import React, { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import Figure from "./Figure";
import Dividor from "../dividor";
import "../../../App.css";

const DesignSection = ({
  Header1 = "",
  Header2 = "",
  Description = "",
  figureStyles = [],
  figureData = [],
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [animationClass, setAnimationClass] = useState("");
  const [screenSize, setScreenSize] = useState("lg");

  useEffect(() => {
    const updateScreenSize = () => {
      const width = window.innerWidth;
      if (width >= 1536) setScreenSize("2xl");
      else if (width >= 1280) setScreenSize("xl");
      else if (width >= 1024) setScreenSize("lg");
      else if (width >= 768) setScreenSize("md");
      else setScreenSize("sm");
    };

    updateScreenSize();
    window.addEventListener("resize", updateScreenSize);

    return () => window.removeEventListener("resize", updateScreenSize);
  }, []);

  const getFigureStyles = (styles) => {
    if (!Array.isArray(styles) || styles.length === 0) return [];
    if (screenSize === "2xl" || screenSize === "xl" || screenSize === "lg") return styles;
    if (screenSize === "md") return styles.slice(1, 4);
    if (screenSize === "sm") return styles.slice(2, 3);
    return [];
  };

  const displayedFigureStyles = getFigureStyles(figureStyles);

  const is2xl = useMediaQuery({ minWidth: 1536 });
  const isXl = useMediaQuery({ minWidth: 1280 });
  const isLg = useMediaQuery({ minWidth: 1024 });
  const isMd = useMediaQuery({ minWidth: 768 });

  const imagesPerPage = is2xl || isXl || isLg ? 5 : isMd ? 3 : 1;

  const handleNext = () => {
    if (currentIndex + imagesPerPage < figureData.length) {
      setAnimationClass("fade-out");
      setTimeout(() => {
        setCurrentIndex((prevIndex) => (prevIndex + imagesPerPage) % figureData.length);
        setAnimationClass("fade-in");
      }, 300);
    }
  };

  const handlePrev = () => {
    if (currentIndex > 0) {
      setAnimationClass("fade-out");
      setTimeout(() => {
        setCurrentIndex((prevIndex) => (prevIndex - imagesPerPage + figureData.length) % figureData.length);
        setAnimationClass("fade-in");
      }, 300);
    }
  };

  return (
    <section className="bg-[#FDFCFB] min-h-screen px-6 py-16">
      <div className="text-center mb-6 md:mb-12">
        <h2 className="text-2xl md:text-3xl 2xl:text-4xl font-bold">{Header1}</h2>
        <h3 className="text-xl md:text-2xl 2xl:text-3xl text-[#B07641] mb-4">{Header2}</h3>
        <Dividor width="w-[100px]" />
        <p className="text-sm md:text-base text-gray-600">{Description}</p>
      </div>
      <div className="relative">
        <button
          className={`w-10 absolute left-0 sm:top-[280px] md:top-[180px] lg:top-[180px] xl:top-[220px] 2xl:top-[220px] transform -translate-y-1/2 ml-[2px] bg-white p-2 rounded-full shadow-lg z-10 ${currentIndex <= 0 && "opacity-50 cursor-not-allowed"}`}
          onClick={handlePrev}
          disabled={currentIndex <= 0}
        >
          <svg viewBox="-4.5 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" className="w-5 h-5">
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <g id="Dribbble-Light-Preview" transform="translate(-345.000000, -6679.000000)" fill={currentIndex <= 0 ? "#C0C0C0" : "#000000"}>
                <g id="icons" transform="translate(56.000000, 160.000000)">
                  <path d="M299.633777,6519.29231 L299.633777,6519.29231 C299.228878,6518.90256 298.573377,6518.90256 298.169513,6519.29231 L289.606572,6527.55587 C288.797809,6528.33636 288.797809,6529.60253 289.606572,6530.38301 L298.231646,6538.70754 C298.632403,6539.09329 299.27962,6539.09828 299.685554,6538.71753 L299.685554,6538.71753 C300.100809,6538.32879 300.104951,6537.68821 299.696945,6537.29347 L291.802968,6529.67648 C291.398069,6529.28574 291.398069,6528.65315 291.802968,6528.26241 L299.633777,6520.70538 C300.038676,6520.31563 300.038676,6519.68305 299.633777,6519.29231" id="arrow_left-[#335]">
                  </path>
                </g>
              </g>
            </g>
          </svg>
        </button>

        <div className={`grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-6 ${animationClass}`}>
          {figureData.length > 0 ? (
            figureData.slice(currentIndex, currentIndex + imagesPerPage).map((data, index) => (
              <Figure
                key={index}
                {...displayedFigureStyles[index % displayedFigureStyles.length]}
                {...data}
                patternId={`pattern-${currentIndex + index}`}
              />
            ))
          ) : (
            <p className="text-center text-gray-500">No figures to display</p>
          )}
        </div>

        <button
          className={`w-10 absolute right-0 sm:top-[280px] md:top-[180px] lg:top-[160px] xl:top-[220px] 2xl:top-[220px] transform -translate-y-1/2 mx-auto bg-white p-2 rounded-full shadow-lg z-10 ${currentIndex + imagesPerPage >= figureData.length && "opacity-50 cursor-not-allowed"}`}
          onClick={handleNext}
          disabled={currentIndex + imagesPerPage >= figureData.length}
        >
          <svg viewBox="-4.5 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" className="w-5 h-5">
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <g id="Dribbble-Light-Preview" transform="translate(-345.000000, -6679.000000)" fill={currentIndex + imagesPerPage >= figureData.length ? "#C0C0C0" : "#000000"}>
                <g id="icons" transform="translate(56.000000, 160.000000)">
                  <path d="M289.366223,6519.29231 L289.366223,6519.29231 C289.771122,6518.90256 290.426623,6518.90256 290.830487,6519.29231 L299.393428,6527.55587 C300.202191,6528.33636 300.202191,6529.60253 299.393428,6530.38301 L290.768354,6538.70754 C290.367597,6539.09329 289.72038,6539.09828 289.314446,6538.71753 L289.314446,6538.71753 C288.899191,6538.32879 288.895049,6537.68821 289.303055,6537.29347 L297.197032,6529.67648 C297.601931,6529.28574 297.601931,6528.65315 297.197032,6528.26241 L289.366223,6520.70538 C288.961324,6520.31563 288.961324,6519.68305 289.366223,6519.29231" id="arrow_right-[#335]">
                  </path>
                </g>
              </g>
            </g>
          </svg>
        </button>
      </div>
    </section>
  );
};

export default DesignSection;

