import React from "react";
import Navbar from "../Components/Common/Navbar/Navbar";
import Footer from "../Components/Common/Footer/MainFooter";

const MainLayout = ({ children }) => {
    return (
        <div className="flex flex-col w-full min-h-screen relative">
            <Navbar />
            <main className="flex-grow">
                {children}
            </main>
            <Footer />
        </div>
    );
};

export default MainLayout;
