import React from "react";
import AboutGroupDigital1 from "../../../Assets/Images/changing/AboutGroup1.png";
import AboutGroupDigital2 from "../../../Assets/Images/changing/AboutGroup2.png";

const AboutGroupDigitalPrinting = () => {
    return (
        <section
            className="w-full px-6 py-12"
            style={{
                background: "linear-gradient(to bottom, #FDFCFB 0%, #E2D1C3 100%)",
            }}
        >
            <div className="max-w-[85%] mx-auto">
                {/* Content Section */}
                <div className="relative grid grid-cols-1 lg:grid-cols-2 gap-8 items-center">
                    {/* Left Section: Paragraph and Image */}
                    <div className="space-y-4">
                        <h3 className="text-[25px] md:text-[29px] lg:text-[25px] xl:text-[29px] 2xl:text-[31px] text-center lg:text-start text-gray-700">About<br />
                            <span className="text-[#B07641]">Group Digital Printing</span>
                        </h3>
                        <div className="hidden sm:flex items-center justify-center lg:hidden flex-row-reverse w-full rounded relative">
                            <img
                                src={AboutGroupDigital1}
                                alt="Decorative Leaf Design"
                                className="w-full md:w-[87%] h-[131px] md:h-[218px] object-cover transform scale-y-[-1]"
                            />
                        </div>
                        <p className="text-[17px] lg:text-[18px] xl:text-[20px] 2xl:text-[25px] text-center lg:text-start text-[#000000] leading-relaxed">
                            A leading platform for fashion innovation, offering cutting-edge
                            fabric printing solutions that empower designers and brands to
                            create unique, high-quality clothing and textiles.
                        </p>
                        <p className="text-[15px] lg:text-[18px] xl:text-[20px] 2xl:text-[25px] text-center lg:text-start text-[#000000] leading-relaxed">
                            A leading platform for fabric printing, empowering designers and brands to create unique, high-quality designs
                        </p>
                        <br />

                        <div className="hidden lg:flex flex-row-reverse w-full rounded lg:ml-[5.5rem] xl:ml-[8.5rem] relative">
                            <img
                                src={AboutGroupDigital1}
                                alt="Decorative Leaf Design"
                                className="w-full h-auto object-cover transform scale-y-[-1]"
                            />
                        </div>
                    </div>

                    {/* Right Section: Image */}
                    <div className="rounded overflow-hidden hidden lg:flex justify-end items-end">
                        <img
                            src={AboutGroupDigital2}
                            alt="Decorative Leaf Design"
                            className="lg:w-[300px] 2xl:w-[400px] xl:w-full max-w-[400px] lg:h-[414px] xl:h-auto max-h-[558px] object-cover"
                        />
                    </div>

                </div>

                {/* Statistics Section */}
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4 text-center mt-12">
                    {/* Statistic Item */}
                    <div>
                        <h3 className="text-xl md:text-xl lg:text-2xl 2xl:text-4xl font-semibold text-gray-800">+90</h3>
                        <p className="text-lg lg:text-xl 2xl:text-2xl text-gray-600 mt-2">Designer</p>
                    </div>
                    <div>
                        <h3 className="text-xl md:text-xl lg:text-2xl 2xl:text-4xl font-semibold text-gray-800">+1,000,000</h3>
                        <p className="text-lg lg:text-xl 2xl:text-2xl text-gray-600 mt-2">DTF Designs</p>
                    </div>
                    <div>
                        <h3 className="text-xl md:text-xl lg:text-2xl 2xl:text-4xl font-semibold text-gray-800">+6,000</h3>
                        <p className="text-lg lg:text-xl 2xl:text-2xl text-gray-600 mt-2">Sublimation Designs</p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default AboutGroupDigitalPrinting;
