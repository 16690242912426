import React from "react";
import {
  ModernDesignSvg1,
  ModernDesignSvg2,
  ButtonArrowSvg,
} from "../../../Assets/Svg/ModernDesign1";
import ModernDesign1 from "../../../Assets/Images/Trend/ModernDesign1.png";
import ModernDesign2 from "../../../Assets/Images/Trend/ModernDesign2.png";
import ModernDesign3 from "../../../Assets/Images/Trend/ModernDesign3.png";

const ModernDesignSection = () => {
  return (
    <section className="flex flex-wrap lg:flex-nowrap py-8 mx-10 2xl:mx-[13rem] gap-4">
      {/* Left Section */}
      <div className="relative w-[681px] 2xl:w-[700px] lg:h-[375px] xl:h-[569px] 2xl:h-[593px] rounded-lg overflow-hidden">
        <ModernDesignSvg1 backgroundImage={ModernDesign1} />
        {/* Overlay */}
        <div className="w-[156px] h-[38px] absolute inset-0 bg-white rounded-full">
          <div className="w-[144px] h-[34px] rounded-full pr-4">
            <button className="w-[144px] h-[34px] bg-white text-black text-xs uppercase px-4 py-2 rounded-full shadow-md border border-[#B07641]">
              Explore Now
            </button>
          </div>
        </div>
        <div className="w-[177px] h-[60px] absolute top-[38px] inset-0 bg-white rounded-full flex items-center justify-start">
          <h1 className="text-black font-bold text-[32px]">MODERN</h1>
        </div>
        <div className="w-[202px] h-[59px] absolute top-[98px] inset-0 bg-white rounded-full flex items-center justify-start">
          <h1 className="text-black font-bold text-[32px]">DESIGN</h1>
        </div>

        {/* Button */}
        <div className="absolute bottom-0 right-0">
          <button className="bg-[#A67640] text-white w-[121px] h-[98px] rounded-[25px] flex items-center justify-center mb-2">
            <ButtonArrowSvg />
          </button>
        </div>
      </div>

      {/* Right Section */}
      <div className="flex flex-col w-[790px] 2xl:w-[800px] lg:h-[375px] xl:h-[569px] 2xl:h-[585px] gap-4">
        {/* Card Component */}
        {[
          {
            img: ModernDesign2,
            title: "Blue Stripe Bow Print",
          },
          {
            img: ModernDesign3,
            title: "Blue Stripe Bow Print",
          },
        ].map((item, index) => (
          <div
            key={index}
            className="w-full lg:h-[275px] xl:h-[275px] 2xl:h-[278px] relative rounded-lg overflow-hidden"
          >
            <ModernDesignSvg2 backgroundImage={item.img} />
            <div className="absolute inset-0 flex justify-between items-end pl-4">
              <div className="w-32 text-white mb-36 ml-5">
                <button className="text-xs py-2 px-6 block border border-[#B07641] bg-transparent rounded-full">Explore Now</button>
                <h2 className="text-2xl mt-2">{item.title}</h2>
              </div>
              <button className="bg-[#A67640] text-white w-[121px] h-[88px] rounded-[25px] flex items-center justify-center mb-2">
                <ButtonArrowSvg />
              </button>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default ModernDesignSection;
