import React from "react";
import { NavLink } from "react-router-dom";

const MegaMenu = ({ isOpen }) => {
    if (!isOpen) return null;

    return (
        <div
            className="bg-white border-gray-200 shadow-sm border-y absolute left-0 right-0 z-20"
            style={{ top: '73px' }}
        >
            <div className="grid max-w-screen-xl px-4 py-5 mx-auto text-sm text-gray-500 md:grid-cols-3 md:px-6">
                <ul className="mx-auto space-y-4 sm:mb-4 md:mb-0 flex flex-col">
                    <li>
                        <NavLink to="/studio/floral" className="hover:text-red-600">
                            Floral
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/studio/floral" className="hover:text-red-600">
                            Ethnic
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/studio/floral" className="hover:text-red-600">
                            Border
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/studio/floral" className="hover:text-red-600">
                            Geometric
                        </NavLink>
                    </li>
                </ul>
                <ul className="mx-auto space-y-4 sm:mb-4 md:mb-0 flex flex-col">
                    <li>
                        <NavLink to="/studio/floral" className="hover:text-red-600">
                            Texture
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/studio/floral" className="hover:text-red-600">
                            Animals
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/studio/floral" className="hover:text-red-600">
                            Dots
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/studio/floral" className="hover:text-red-600">
                            Tartan
                        </NavLink>
                    </li>
                </ul>
                <ul className="mx-auto space-y-4 sm:mb-4 md:mb-0 flex flex-col">
                    <li>
                        <NavLink to="/studio/floral" className="hover:text-red-600">
                            Cartoon
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/studio/floral" className="hover:text-red-600">
                            Space
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/studio/floral" className="hover:text-red-600">
                            DTF
                        </NavLink>
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default MegaMenu;
