import React from "react";

const ToasterAuth = ({ message, type }) => {
  if (!message) return null;

  const bgColor = type === "success" ? "bg-green-500" : "bg-red-500";
  const textColor = "text-white";

  return (
    <div
      className={`flex items-center justify-center w-full max-w-sm mx-auto p-2 mb-4 rounded-md text-sm text-center ${bgColor} ${textColor}`}
    >
      {message}
    </div>
  );
};

export default ToasterAuth;