import React, { useState, useEffect } from "react";
import sectionImage from '../../../Assets/Images/Home/SideBanner.webp';
import backgroundImage from '../../../Assets/Images/Home/BannerHome.webp';
import backgroundMobileImage from '../../../Assets/Images/Home/BannerMobileHome.webp';

const BannerHome = () => {
    const [background, setBackground] = useState(backgroundImage);

    const updateBackgroundImage = () => {
        if (window.innerWidth <= 1024) {
            setBackground(backgroundMobileImage);
        } else {
            setBackground(backgroundImage);
        }
    };

    useEffect(() => {
        updateBackgroundImage(); // Set initial value
        window.addEventListener("resize", updateBackgroundImage);
        return () => {
            window.removeEventListener("resize", updateBackgroundImage);
        };
    }, []);

    return (
        <div className="relative mb-8 overflow-hidden">
            {/* Main Section */}
            <section
                className="w-full h-[600px] 2xl:h-[765px] -translate-y-[120px] flex items-center"
                style={{
                    backgroundImage: `url(${background})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    loading: "lazy"
                }}
            >
                {/* Left Column */}
                <div className="flex-1 xl:flex-[0.9] py-16 px-5 mt-[150px] xl-mt-20 text-white">
                    <p className="text-[28px] xl:text-[36px] text-center mb-6">
                        CREATE STUNNING DESIGN WITH
                    </p>
                    <div className="flex sm:block 2sm:block md:flex sm:text-center 2sm:text-center md:text-start w-full">
                        <p className="w-1/2 sm:w-full 2sm:w-full text-[28px] xl:text-[30px] lg:text-center sm:mb-4 2sm:mb-4">
                            THE FINEST FABRIC AT
                        </p>
                        <p className="w-1/2 sm:w-full 2sm:w-full sm:text-[13px] 2sm:text-[13px] md:text-[17px] sm:mb-4 2sm:mb-4">
                            Fabrics, custom designs, and printing on apparel to create your unique look
                        </p>
                    </div>

                    <p className="text-[28px] xl:text-[36px] text-center mb-6">
                        GROUP DIGITAL PRINTING
                    </p>
                </div>

                {/* Right Column */}
                <div className="w-1/3 2xl:w-[31.333333%] bg-white rounded-t-[250px] 2xl:rounded-tl-[310px] 2xl:rounded-tr-[310px] p-4 hidden xl:flex 2xl:flex items-center justify-center translate-y-40 translate-x-4 xl:translate-x-9 2xl:translate-x-[27px]">
                    <div className="p-4">
                        <img
                            src={sectionImage}
                            alt="Showcase"
                            className="max-w-full 2xl:w-[506px] h-auto rounded-lg"
                            loading="lazy"
                        />
                    </div>
                </div>
            </section>

            {/* Stats Section */}
            <div className="sm:w-full 2sm:w-full md:w-full lg:w-full xl:w-2/3 flex-[0.1] mt-12 text-gray-800 -translate-y-[120px]">
                <div className="sm:block 2sm:block md:flex flex-wrap justify-center">
                    <div className="text-center md:mr-28 lg:mr-48 xl:mr-48 2xl:mr-48">
                        <h3 className="text-3xl text-[#B07641] mb-2">+90</h3>
                        <p className="text-sm ">Designer</p>
                    </div>
                    <div className="text-center sm:my-9 2sm:my-9 md:my-0 lg:ml-10 xl:ml-10 2xl:ml-10">
                        <h3 className="text-3xl text-[#B07641] mb-2">+1,000,000 </h3>
                        <p className="text-sm">DTF Designs</p>
                    </div>
                    <div className="text-center md:ml-28 lg:ml-48 xl:ml-48 2xl:ml-48">
                        <h3 className="text-3xl text-[#B07641] mb-2">+6,000</h3>
                        <p className="text-sm">Sublimation Designs</p>
                    </div>
                </div>
            </div>

            <div>
                <div className="flex -translate-y-4">
                    <div className="text-start">
                        <h3 className="text-5xl text-[#B07641] opacity-20 -translate-x-3 mb-4">GITAL</h3>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BannerHome;
