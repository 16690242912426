import React from "react";
import { NavLink } from "react-router-dom";

const PrimaryNav = ({ onToggleMegaMenu }) => {
  return (
    <div className="hidden 2xl:flex xl:flex lg:flex md:hidden items-center h-full space-x-1">
      <NavLink
        to="/studio/floral"
        onMouseEnter={onToggleMegaMenu} 
        onMouseLeave={onToggleMegaMenu}
        className="pt-[20px] 2xl:pt-[24px] px-3 mb-[13px] h-[50px] 2xl:h-[60px] text-gray-700 2xl:text-[20px] hover:text-gray-900 border-b-2 border-transparent hover:border-[#B07641] transition-all flex items-center"
      >
        Designs
        <svg className="w-2.5 h-2.5 ml-2" aria-hidden="true" fill="none" viewBox="0 0 10 6">
          <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1l4 4 4-4" />
        </svg>
      </NavLink>

      <NavLink
        to="/trends"
        className="pt-[20px] 2xl:pt-[25px] px-3 mb-[13px] h-[50px] 2xl:h-[60px] text-gray-700 2xl:text-[20px] hover:text-gray-900 border-b-2 border-transparent hover:border-[#B07641] transition-all"
      >
        Trends
      </NavLink>

      <NavLink
        to="/about"
        className="pt-[20px] 2xl:pt-[25px] px-3 mb-[13px] h-[50px] 2xl:h-[60px] text-gray-700 2xl:text-[20px] hover:text-gray-900 border-b-2 border-transparent hover:border-[#B07641] transition-all"
      >
        About Us
      </NavLink>

      <NavLink
        to="/Printer"
        className="pt-[20px] 2xl:pt-[25px] px-3 mb-[13px] h-[50px] 2xl:h-[60px] text-gray-700 2xl:text-[20px] hover:text-gray-900 border-b-2 border-transparent hover:border-[#B07641] transition-all"
      >
        Printer
      </NavLink>
    </div>
  );
};

export default PrimaryNav;
