import React from "react";
import About1 from "../../../Assets/Images/About/About1.png";
import About2 from "../../../Assets/Images/About/About2.png";

const AboutUs = () => {
  return (
    <section className="py-6 md:py-12 2xl:py-16 bg-transparent min-h-[530px] md:min-h-[650px]">
      {/* Header */}
      <div className="text-center mb-12">
        <h2 className="sm:text-3xl md:text-3xl lg:text-4xl xl:text-3xl 2xl:text-5xl text-[#B07641] font-semibold">
          About Us
        </h2>
      </div>

      {/* Content */}
      <div className="flex flex-col md:flex-row items-center gap-12 md:gap-8 ">
        {/* Left Text Content */}
        <div className="flex-1 text-center md:text-left px-4 sm:px-6 md:px-8 lg:px-12 xl:px-32 2xl:px-48">
          <h3 className="text-2xl md:text-[22px] lg:text-[24px] xl:text-[29px] 2xl:text-[35px] font-medium leading-relaxed mb-4">
            Welcome to{" "}
            <span className="text-[#B07641]">Digital Printing,{" "}</span>
            where creativity meets quality in design and printing!
          </h3>
          <p className="sm:text-[18px] md:text-[16px] lg:text-[16px] xl:text-[23px] 2xl:text-[26px] text-[#B8B8B8] text-center md:text-justify leading-7 mb-6">
          We specialize in high-quality fabrics and custom garment printing .
          </p>
          <div className="py-[4px] w-[148px] 2xl:w-[200px] border border-[#B07641] rounded-br-[30px] rounded-tl-[30px] flex items-center justify-center mx-auto md:mx-0">
            <button className="text-[14px] sm:text-[16px] 2xl:text-[25px] bg-[#B07641] text-white py-2 sm:py-3 px-6 sm:px-[29px] rounded-br-3xl rounded-tl-3xl shadow-lg hover:bg-[#cd8542] hover:scale-105 transform transition duration-300">
              Shop Now
            </button>
          </div>
        </div>

        {/* Right Image Section */}
        <div className="flex-1 relative">
          <div className="w-full h-auto relative">
            {/* Teardrop Shape */}
            <div className="absolute w-[200px] sm:w-[250px] md:w-[300px] left-[0.5rem] top-[25px] sm:top-[-150px] clip-teardrop overflow-hidden z-30 hidden md:block lg:hidden xl:block">
              <img
                src={About1}
                alt="Teardrop"
                className="object-cover w-full h-full"
              />
            </div>
            <div className="absolute w-[200px] sm:w-[250px] md:w-[304px] left-[-2px] top-[25px] sm:top-[-150px] clip-teardrop overflow-hidden z-20 hidden md:block lg:hidden xl:block">
              <svg viewBox="0 0 294 385" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.5 0.5H94C204.181 0.5 293.5 89.8192 293.5 200V238C293.5 318.91 227.91 384.5 147 384.5C66.0903 384.5 0.5 318.91 0.5 238V0.5Z" stroke="#B07641" />
              </svg>
            </div>
            {/* Background Shape */}
            <div className="absolute w-[180px] sm:w-[220px] md:w-[262px] lg:w-[294px] xl:w-[262px] right-0 top-[-100px] sm:top-[-150px] lg:top-[-391px] xl:top-[-337px] overflow-hidden hidden lg:block xl:block">
              <img
                src={About2}
                alt="Background"
                className="object-cover w-full h-full"
              />
            </div>
            <div className="absolute w-[200px] sm:w-[240px] md:w-[275px] lg:w-[301px] xl:w-[275px] right-0 top-[-125px] sm:top-[-185px] lg:top-[-420px] xl:top-[-365px] overflow-hidden hidden xl:block">
              <svg width="284" height="573" viewBox="0 0 284 573" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.5 -16.5H412.5V373C412.5 483.181 323.181 572.5 213 572.5H200C89.8192 572.5 0.5 483.181 0.5 373V-16.5Z" stroke="#B07641" />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
