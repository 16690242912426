import React from 'react';
import Banner from '../Components/Opal/Home/Banner';
import DesignSection from '../Components/Opal/Home/BestDesign';
import BenefitsSection from '../Components/Opal/Home/BenefitsSection';
import AboutUsSection from '../Components/Opal/Home/AboutUsSection';
import DigitalPrintingSection from '../Components/Opal/Home/DigitalPrintingSection';

import BD1 from "../Assets/Images/Home/BD1.webp";
import BD2 from "../Assets/Images/Home/BD2.webp";
import BD3 from "../Assets/Images/Home/BD3.webp";
import BD4 from "../Assets/Images/Home/BD4.webp";
import BD5 from "../Assets/Images/Home/BD5.webp";
import BD6 from "../Assets/Images/Home/BD6.webp";
import BD7 from "../Assets/Images/Home/BD7.webp";
import BD8 from "../Assets/Images/Home/BD8.webp";
import BD9 from "../Assets/Images/Home/BD9.webp";
import BD10 from "../Assets/Images/Home/BD10.webp";

const HomePage = () => {
    const figureStyles1 = [
        {
            translateY: "translate-y-6",
            heightImg: "lg:h-[16rem] 1340:h-[24rem] xl:h-[24rem] 2xl:h-[27rem]",
            svgWidth: 242,
            svgHeight: 349,
            svgPathD:
                "M0 30.5095C0 12.281 16.1227 -1.73483 34.1739 0.801289L216.174 26.3715C230.984 28.4523 242 41.1239 242 56.0798V295.935C242 311.1 230.683 323.88 215.63 325.715L33.6301 347.9C15.7543 350.08 0 336.129 0 318.121V30.5095Z",
            cartIconRight: "xl:right-24 2xl:right-[8rem]",
            bottomBtn: "1340:bottom-[13px]"
        },
        {
            translateY: "translate-y-0",
            heightImg: "md:h-[23rem] lg:h-[17.5rem] 1340:h-[27rem] xl:h-[27rem] 2xl:h-[30rem]",
            svgWidth: 242,
            svgHeight: 391,
            svgPathD:
                "M0 30.8734C0 12.1806 16.9096 -1.96046 35.3075 1.3466L217.307 34.0615C231.6 36.6305 242 49.067 242 63.5882V329.207C242 343.819 231.472 356.305 217.071 358.775L35.0706 389.986C16.743 393.129 0 379.013 0 360.417V30.8734Z",
            cartIconRight: "xl:right-24 2xl:right-[8rem]",
            bottomBtn: "1340:bottom-[13px]"
        },
        {
            translateY: "translate-y-10",
            heightImg: "sm:h-[31rem] md:h-[24rem] lg:h-[19rem] 1340:h-[29rem] xl:h-[29rem] 2xl:h-[31rem]",
            svgWidth: 242,
            svgHeight: 401,
            svgPathD:
                "M0 30C0 13.4315 13.4315 0 30 0H212C228.569 0 242 13.4315 242 30V371C242 387.569 228.569 401 212 401H30C13.4315 401 0 387.569 0 371V30Z",
            cartIconRight: "xl:right-28 2xl:right-[9.5rem]",
            bottomBtn: "1340:bottom-[13px]"
        },
        {
            translateY: "translate-y-0",
            heightImg: "md:h-[23rem] lg:h-[17.5rem] 1340:h-[27rem] xl:h-[27rem] 2xl:h-[30rem]",
            svgWidth: 242,
            svgHeight: 391,
            svgPathD:
                "M242 30.8734C242 12.1806 225.09 -1.96046 206.693 1.3466L24.6925 34.0615C10.4003 36.6305 0 49.067 0 63.5882V329.207C0 343.819 10.5276 356.305 24.9294 358.775L206.929 389.986C225.257 393.129 242 379.013 242 360.417V30.8734Z",
            cartIconRight: "lg:right-22 xl:right-32 2xl:right-[10rem]",
            bottomBtn: "1340:bottom-[13px]"
        },
        {
            translateY: "translate-y-6",
            heightImg: "lg:h-[17rem] 1340:h-[24rem] xl:h-[24rem] 2xl:h-[27rem]",
            svgWidth: 242,
            svgHeight: 349,
            svgPathD:
                "M242 30.5095C242 12.281 225.877 -1.73483 207.826 0.801289L25.8261 26.3715C11.0157 28.4523 0 41.1239 0 56.0798V176.25V295.935C0 311.1 11.3167 323.88 26.3698 325.715L208.37 347.9C226.246 350.08 242 336.129 242 318.121V30.5095Z",
            cartIconRight: "xl:right-[7.5rem] 2xl:right-[9.5rem]",
            bottomBtn: "1340:bottom-[13px]"
        },
    ];

    const figureStyles2 = [
        {
            translateY: "translate-y-0",
            heightImg: "lg:h-[16rem] 1340:h-[25rem] xl:h-[25rem] 2xl:h-[27rem]",
            svgWidth: 242,
            svgHeight: 349,
            svgPathD:
                "M242 30.5095C242 12.281 225.877 -1.73483 207.826 0.801289L25.8261 26.3715C11.0157 28.4523 0 41.1239 0 56.0798V176.25V295.935C0 311.1 11.3167 323.88 26.3698 325.715L208.37 347.9C226.246 350.08 242 336.129 242 318.121V30.5095Z",
            cartIconRight: "xl:right-[7.5rem] 2xl:right-[9.5rem]",
            bottomBtn: "1340:bottom-[13px]"
        },
        {
            translateY: "translate-y-0",
            heightImg: "md:h-[23rem] lg:h-[17.5rem] 1340:h-[28rem] xl:h-[28rem] 2xl:h-[30rem]",
            svgWidth: 242,
            svgHeight: 391,
            svgPathD:
                "M242 30.8734C242 12.1806 225.09 -1.96046 206.693 1.3466L24.6925 34.0615C10.4003 36.6305 0 49.067 0 63.5882V329.207C0 343.819 10.5276 356.305 24.9294 358.775L206.929 389.986C225.257 393.129 242 379.013 242 360.417V30.8734Z",
            cartIconRight: "xl:right-[7rem] 2xl:right-[9.5rem]",
            bottomBtn: "1340:bottom-[27px]"
        },
        {
            translateY: "translate-y-10",
            heightImg: "sm:h-[31rem] md:h-[24rem] lg:h-[19rem] 1340:h-[29rem] xl:h-[29rem] 2xl:h-[31rem]",
            svgWidth: 242,
            svgHeight: 401,
            svgPathD:
                "M0 30C0 13.4315 13.4315 0 30 0H212C228.569 0 242 13.4315 242 30V371C242 387.569 228.569 401 212 401H30C13.4315 401 0 387.569 0 371V30Z",
            cartIconRight: "xl:right-28 2xl:right-[9.4rem]",
            bottomBtn: "1340:bottom-[13px]"
        },
        {
            translateY: "translate-y-0",
            heightImg: "md:h-[23rem] lg:h-[17.5rem] 1340:h-[28rem] xl:h-[28rem] 2xl:h-[30rem]",
            svgWidth: 242,
            svgHeight: 391,
            svgPathD:
                "M0 30.8734C0 12.1806 16.9096 -1.96046 35.3075 1.3466L217.307 34.0615C231.6 36.6305 242 49.067 242 63.5882V329.207C242 343.819 231.472 356.305 217.071 358.775L35.0706 389.986C16.743 393.129 0 379.013 0 360.417V30.8734Z",
            cartIconRight: "xl:right-[6rem] 2xl:right-[8.5rem]",
            bottomBtn: "1340:bottom-[27px]"
        },
        {
            translateY: "translate-y-0",
            heightImg: "lg:h-[16rem] 1340:h-[25rem] xl:h-[25rem] 2xl:h-[27rem]",
            svgWidth: 242,
            svgHeight: 349,
            svgPathD:
                "M0 30.5095C0 12.281 16.1227 -1.73483 34.1739 0.801289L216.174 26.3715C230.984 28.4523 242 41.1239 242 56.0798V295.935C242 311.1 230.683 323.88 215.63 325.715L33.6301 347.9C15.7543 350.08 0 336.129 0 318.121V30.5095Z",
            cartIconRight: "xl:right-24 2xl:right-[8.5rem]",
            bottomBtn: "1340:bottom-[13px]"
        },
    ];

    // General data
    const figureData = [
        {
            imageSrc: BD1,
            title: "Abstract Textured Floral Background",
            description: "Lorem ipsum dolor sit amet consectetur.",
        },
        {
            imageSrc: BD2,
            title: "Modern Minimalist Design",
            description: "Discover the beauty of simplicity.",
        },
        {
            imageSrc: BD3,
            title: "Modern Minimalist Design",
            description: "Discover the beauty of simplicity.",
        },
        {
            imageSrc: BD4,
            title: "Modern Minimalist Design",
            description: "Discover the beauty of simplicity.",
        },
        {
            imageSrc: BD5,
            title: "Modern Minimalist Design",
            description: "Discover the beauty of simplicity.",
        },
        {
            imageSrc: BD6,
            title: "Modern Minimalist Design",
            description: "Discover the beauty of simplicity.",
        },
        {
            imageSrc: BD7,
            title: "Modern Minimalist Design",
            description: "Discover the beauty of simplicity.",
        },
        {
            imageSrc: BD8,
            title: "Modern Minimalist Design",
            description: "Discover the beauty of simplicity.",
        },
        {
            imageSrc: BD9,
            title: "Modern Minimalist Design",
            description: "Discover the beauty of simplicity.",
        },
        {
            imageSrc: BD10,
            title: "Modern Minimalist Design",
            description: "Discover the beauty of simplicity.",
        },
    ];
    return (
        <div className='overflow-hidden'>
            <Banner />
            <DesignSection
                Header1="We Provide You The"
                Header2="Best Design"
                Description="Top Designs Crafted for Excellence and Style"
                figureStyles={figureStyles1}
                figureData={figureData}
            />
            <BenefitsSection />
            <DesignSection 
            Header1="Best Design"
            Header2="Offers"
            Description="Top Designs Crafted for Excellence and Style"
            figureStyles={figureStyles2} 
            figureData={figureData} 
            />
            <AboutUsSection />
            <DigitalPrintingSection />
        </div>
    );
};

export default HomePage;
