import React, { useState } from "react";
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';

const MobileFooter = () => {

    const [isExploreOpen, setIsExploreOpen] = useState(false);
    const [isHelpOpen, setIsHelpOpen] = useState(false);
    const [isAboutOpen, setIsAboutOpen] = useState(false);
    const [isFindUsOpen, setIsFindUsOpen] = useState(false);

    const toggleExplore = () => setIsExploreOpen(!isExploreOpen);
    const toggleHelp = () => setIsHelpOpen(!isHelpOpen);
    const toggleAbout = () => setIsAboutOpen(!isAboutOpen);
    const toggleFindUs = () => setIsFindUsOpen(!isFindUsOpen);

    return (
        <div className="lg:hidden">
            <div className="space-y-6 px-[25px]">
                <div>
                    <p onClick={toggleExplore} className="text-lg font-bold cursor-pointer flex items-center justify-between">
                        <span>Explore</span> {isExploreOpen ? <FaChevronUp /> : <FaChevronDown />}
                    </p>
                    {isExploreOpen && (
                        <div className="space-y-2 mt-2">
                            <a href="tel:850-123-5021" className="block text-lg text-deep-purple-accent-400 hover:text-deep-purple-800">All Design</a>
                            <a href="mailto:info@lorem.mail" className="block text-lg text-deep-purple-accent-400 hover:text-deep-purple-800">Print Trends</a>
                            <a href="https://www.google.com/maps" target="_blank" rel="noopener noreferrer" className="block text-lg text-deep-purple-accent-400 hover:text-deep-purple-800">Catwalk Analysis</a>
                        </div>
                    )}
                </div>
                <hr className="border-gray-300" />
                <div>
                    <p onClick={toggleHelp} className="text-lg font-bold cursor-pointer flex items-center justify-between">
                        Help {isHelpOpen ? <FaChevronUp /> : <FaChevronDown />}
                    </p>
                    {isHelpOpen && (
                        <div className="space-y-2 mt-2">
                            <a href="tel:850-123-5021" className="block text-lgtext-deep-purple-accent-400 hover:text-deep-purple-800">Support</a>
                            <a href="mailto:info@lorem.mail" className="block text-lg text-deep-purple-accent-400 hover:text-deep-purple-800">Licensing</a>
                        </div>
                    )}
                </div>
                <hr className="border-gray-300" />
                <div>
                    <p onClick={toggleAbout} className="text-lg font-bold cursor-pointer flex items-center justify-between">
                        About {isAboutOpen ? <FaChevronUp /> : <FaChevronDown />}
                    </p>
                    {isAboutOpen && (
                        <div className="space-y-2 mt-2">
                            <a href="tel:850-123-5021" className="block text-lg text-deep-purple-accent-400 hover:text-deep-purple-800">About Us</a>
                            <a href="mailto:info@lorem.mail" className="block text-lg text-deep-purple-accent-400 hover:text-deep-purple-800">Sell your Designs</a>
                        </div>
                    )}
                </div>
                <hr className="border-gray-300" />
                {/* Find Us Section */}
                <div onClick={toggleFindUs} className="flex justify-between my-5 cursor-pointer">
                    <p className="text-lg font-bold flex items-start justify-between w-full">
                        <span>Find Us</span> {isFindUsOpen ? <FaChevronUp /> : <FaChevronDown />}
                    </p>
                </div>
                {isFindUsOpen && (
                    <div className="mt-2 space-y-4">
                        <a href="/" className="flex items-start justify-start space-x-2 text-gray-500 transition-colors duration-300 hover:text-deep-purple-accent-400">
                            <svg viewBox="0 0 24 24" fill="#08a0e9" className="h-5">
                                <path d="M24,4.6c-0.9,0.4-1.8,0.7-2.8,0.8c1-0.6,1.8-1.6,2.2-2.7c-1,0.6-2,1-3.1,1.2c-0.9-1-2.2-1.6-3.6-1.6 c-2.7,0-4.9,2.2-4.9,4.9c0,0.4,0,0.8,0.1,1.1C7.7,8.1,4.1,6.1,1.7,3.1C1.2,3.9,1,4.7,1,5.6c0,1.7,0.9,3.2,2.2,4.1 C2.4,9.7,1.6,9.5,1,9.1c0,0,0,0,0,0.1c0,2.4,1.7,4.4,3.9,4.8c-0.4,0.1-0.8,0.2-1.3,0.2c-0.3,0-0.6,0-0.9-0.1c0.6,2,2.4,3.4,4.6,3.4 c-1.7,1.3-3.8,2.1-6.1,2.1c-0.4,0-0.8,0-1.2-0.1c2.2,1.4,4.8,2.2,7.5,2.2c9.1,0,14-7.5,14-14c0-0.2,0-0.4,0-0.6 C22.5,6.4,23.3,5.5,24,4.6z"></path>
                            </svg>
                            <p>Twitter</p>
                        </a>
                        <a href="/" className="flex items-start justify-start space-x-2 text-gray-500 transition-colors duration-300 hover:text-deep-purple-accent-400">
                            <svg viewBox="0 0 30 30" className="h-6">
                                <defs>
                                    <linearGradient id="instagram-gradient" x1="0%" y1="0%" x2="100%" y2="100%">
                                        <stop offset="0%" style={{ stopColor: "#515BD4", stopOpacity: 1 }} />
                                        <stop offset="25%" style={{ stopColor: "#8134AF", stopOpacity: 1 }} />
                                        <stop offset="50%" style={{ stopColor: "#DD2A7B", stopOpacity: 1 }} />
                                        <stop offset="75%" style={{ stopColor: "#F58529", stopOpacity: 1 }} />
                                        <stop offset="100%" style={{ stopColor: "#FEDA77", stopOpacity: 1 }} />
                                    </linearGradient>
                                </defs>
                                <circle cx="15" cy="15" r="4" fill="url(#instagram-gradient)"></circle>
                                <path d="M19.999,3h-10C6.14,3,3,6.141,3,10.001v10C3,23.86,6.141,27,10.001,27h10C23.86,27,27,23.859,27,19.999v-10   C27,6.14,23.859,3,19.999,3z M15,21c-3.309,0-6-2.691-6-6s2.691-6,6-6s6,2.691,6,6S18.309,21,15,21z M22,9c-0.552,0-1-0.448-1-1   c0-0.552,0.448-1,1-1s1,0.448,1,1C23,8.552,22.552,9,22,9z" fill="url(#instagram-gradient)"></path>
                            </svg>
                            <p>Instagram</p>
                        </a>
                        <a href="/" className="flex items-start justify-start space-x-2 text-gray-500 transition-colors duration-300 hover:text-deep-purple-accent-400">
                            <svg viewBox="0 0 24 24" fill="#3B5998" className="h-5">
                                <path d="M22,0H2C0.895,0,0,0.895,0,2v20c0,1.105,0.895,2,2,2h11v-9h-3v-4h3V8.413c0-3.1,1.893-4.788,4.659-4.788 c1.325,0,2.463,0.099,2.795,0.143v3.24l-1.918,0.001c-1.504,0-1.795,0.715-1.795,1.763V11h4.44l-1,4h-3.44v9H22c1.105,0,2-0.895,2-2 V2C24,0.895,23.105,0,22,0z"></path>
                            </svg>
                            <p>Facebook</p>
                        </a>
                    </div>
                )}
                <hr className="border-gray-300" />
            </div>
        </div>
    );
};

export default MobileFooter;
