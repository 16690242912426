import React from "react";

function Sidebar({ onItemClick }) {
  const userName = localStorage.getItem('userName');
  const userEmail = localStorage.getItem('userEmail');
  return (
    <div className="w-full lg:w-[22%] bg-white p-6 pt-10 pb-[3rem] shadow-xl rounded-xl border-t-2 border-gray-100 mb-8 lg:mb-0 lg:mx-6">
      <div className="flex flex-col items-center">
        <img
          className="w-24 h-24 rounded-full object-cover"
          src="https://via.placeholder.com/150"
          alt="Profile"
        />
        <h2 className="mt-4 text-lg md:text-xl font-semibold">{userName}</h2>
        <p className="text-gray-500">{userEmail}</p>
      </div>
      <div className="mt-10">
        <button
          className="flex items-center w-full py-2 px-4 text-black font-semibold rounded-xl bg-orange-100" 
        >
          <svg
            fill="#B07641"
            className="w-6 h-6 mr-4"
            viewBox="0 0 32 32"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M16 15.503A5.041 5.041 0 1 0 16 5.42a5.041 5.041 0 0 0 0 10.083zm0 2.215c-6.703 0-11 3.699-11 5.5v3.363h22v-3.363c0-2.178-4.068-5.5-11-5.5z" />
          </svg>
          Personal Information
        </button>
      </div>
    </div>
  );
}

export default Sidebar;
