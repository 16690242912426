import React from "react";

const CartItem = ({ product }) => {
  return (
    <div className="flex p-6">
      <div className="flex-shrink-0">
        <img
          src={product.image}
          alt={product.name}
          className="w-20 h-20 rounded-md object-cover"
        />
      </div>
      <div className="ml-4 flex-1 flex flex-col">
        <div>
          <div className="flex justify-between text-base font-medium text-gray-900">
            <h3>{product.name}</h3>
            <p className="ml-4">${product.price}</p>
          </div>
          <p className="mt-1 text-sm text-gray-500">{product.description}</p>
        </div>
        <div className="flex-1 flex items-end justify-between text-sm">
          <p
            className={
              product.availability === "In stock"
                ? "text-green-600"
                : "text-yellow-600"
            }
          >
            {product.availability}
          </p>
          <div className="flex">
            <button
              type="button"
              className="ml-4 font-medium text-red-600 hover:text-red-500"
            >
              Remove
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CartItem;
