import React from "react";
import Sidebar from "./Sidebar";
import MainContent from "./MainContent";
import HeaderSection from "./HeaderSection";

function ProfilePage() {

    return (
        <div className="relative overflow-hidden">
            <HeaderSection />
            {/* Person Info Section */}
            <div className="relative mt-[5rem] mb-8 bg-transparent w-full flex flex-col lg:flex-row items-center lg:items-start justify-center z-30 px-4 md:px-8">
                <Sidebar  />
                <MainContent />
            </div>
        </div>
    );
}

export default ProfilePage;
