import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { AuthProvider } from "./contexts/AuthContext";
import { ToasterProvider } from "./contexts/ToasterContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <AuthProvider>
    <ToasterProvider>
      <App />
    </ToasterProvider>
  </AuthProvider>
);