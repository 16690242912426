import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider1 from '../../../Assets/Images/Trend/Slider1.png';
import Slider2 from '../../../Assets/Images/Trend/Slider2.png';
import Slider3 from '../../../Assets/Images/Trend/Slider3.png';
import Slider4 from '../../../Assets/Images/Trend/Slider4.png';

const ImageSlider = () => {
    const images = [
        Slider1,
        Slider2,
        Slider3,
        Slider4,
    ];

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        arrows: false,
    };

    return (
        <div className="bg-transparent flex items-center justify-center">
            <div className="w-full h-full mx-auto">
                <Slider {...settings}>
                    {images.map((image, index) => (
                        <div key={index}>
                            <img
                                src={image}
                                alt={`Slide ${index}`}
                                className="w-full h-full object-cover"
                            />
                        </div>
                    ))}
                </Slider>
            </div>
        </div>
    );
};

export default ImageSlider;
