import React from "react";
import TrendHeader1 from '../../../Assets/Images/changing/trendHeader1.png';
import TrendHeader2 from '../../../Assets/Images/changing/trendHeader2.png';
import TrendHeader3 from '../../../Assets/Images/changing/trendHeader3.png';
import TrendHeader4 from '../../../Assets/Images/changing/trendHeader4.png';

const TrendDesignSection = () => {
  return (
    <section className="w-[90%] bg-white rounded-tr-[100px] overflow-hidden mx-auto relative rotate-[-90deg] md:rotate-0">
      {/* Header Section */}
      <div className="relative">
        <img
          src={TrendHeader1}
          alt="Trend Design"
          className="w-full h-[190px] lg:h-[234px] 2xl:h-[300px] object-cover"
        />
        <div className="w-[175px] md:w-full absolute inset-0 top-0 flex flex-col justify-center items-center md:items-start rotate-90 md:rotate-0 p-6">
          <h2 className="text-3xl xl:text-4xl 2xl:text-5xl text-white my-10">
            Trend <br /> Design
          </h2>
          <p className="text-white text-base 2xl:text-[20px] mt-2 hidden md:block">Innovative fabrics and prints for every season</p>
        </div>
      </div>

      {/* Grid Section */}
      <div className="grid grid-cols-2 md:grid-cols-3 gap-4 py-0 md:py-6">
        {/* First Card */}
        <div className="relative hidden md:block rounded-bl-[180px] rounded-lg overflow-hidden">
          <img
            src={TrendHeader2}
            alt="Tailored Design"
            className="w-full md:h-[165px] lg:h-[210px] xl:h-[260px] 2xl:h-[310px] object-cover"
          />
          <div className="absolute inset-0 left-0 flex justify-end items-center p-4">
            <p className="md:w-[153px] lg:w-[175px] xl:w-[250px] 2xl:w-[270px] text-white lg:text-[18px] xl:text-[26px] 2xl:text-[31px] text-start">
              Tailored design and print services, made for you.
            </p>
          </div>
        </div>

        {/* Second Card */}
        <div className="relative overflow-hidden">
          <img
            src={TrendHeader3}
            alt="New Design"
            className="w-full h-[191px] lg:h-[210px] xl:h-[255px] 2xl:h-[307px] object-cover"
          />
          <span className="absolute top-[6rem] md:top-4 right-4 bg-white text-black text-sm lg:text-[12px] xl:text-sm px-3 py-3 rounded-full rotate-90 md:rotate-0">
            New
          </span>
        </div>

        {/* Third Card */}
        <div className="relative overflow-hidden">
          <img
            src={TrendHeader4}
            alt="New Design"
            className="w-full h-[191px] lg:h-[210px] xl:h-[255px] 2xl:h-[307px] object-cover"
          />
          <span className="absolute top-[6rem] md:top-4 right-4 bg-white text-black text-sm lg:text-[12px] xl:text-sm px-3 py-3 rounded-full rotate-90 md:rotate-0">
            New
          </span>
        </div>
      </div>
    </section>
  );
};

export default TrendDesignSection;
