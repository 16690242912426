import React from "react";

function HeaderSection() {
    return (
        <section className="absolute w-full h-[230px] bg-[#53dbcd] z-0 top-0">
            <img
                src="https://production-cdn1.patternbank.com/uploads/media/sources/000/007/633/1724940357/original/Premium_Header2.3-v8.jpg?1724940357"
                alt="Header background"
                className="absolute w-full h-full object-cover"
            />
        </section>
    );
}

export default HeaderSection;
