import React from "react";
import { useNavigate, NavLink } from "react-router-dom";
import { useAuth } from "../../../contexts/AuthContext";

const ProfileDropdown = ({ profileDropdownRef, setIsProfileCartOpen }) => {
    const { handleLogout } = useAuth();
    const userName = localStorage.getItem('userName');
    const userEmail = localStorage.getItem('userEmail');
    const verify = JSON.parse(localStorage.getItem('verify'));
    const navigate = useNavigate();

    const onLogout = async () => {
        try {
            await handleLogout();
            setIsProfileCartOpen(false);
            navigate("/");
        } catch (error) {
            alert(error.message);
        }
    };

    return (
        <div
            ref={profileDropdownRef}
            className="absolute right-[-21px] top-[2rem] mt-2 w-64 border-2 border-black bg-white shadow-xl rounded-lg p-4 z-50"
        >
            {/* Dropdown Arrow */}
            <div
                className="absolute top-[-8px] right-[17px] transform -translate-x-1/2 w-0 h-0 
                border-l-8 border-r-8 border-b-8 border-transparent border-b-black"
            />

            {/* User Profile Section */}
            <div className="flex items-center space-x-3 mb-4">
                <img
                    src="https://via.placeholder.com/150"
                    alt="Profile"
                    className="w-12 h-12 rounded-full object-cover"
                />
                <div>
                    <p className="font-semibold">{userName || "Guest"}</p>
                    <p
                        className="block text-sm text-gray-500 overflow-hidden text-ellipsis whitespace-nowrap max-w-[180px]"
                    >
                        {userEmail || "guest@example.com"}
                    </p>
                    <p className="text-xs text-gray-400">
                        {verify ? "Verified User" : "Not Verified"}
                    </p>
                </div>
            </div>

            <hr className="my-4" />

            {/* View Profile Link */}
            <NavLink
                to="/PersonalInfo"
                className="block text-center bg-black text-white py-2 rounded-lg hover:bg-gray-800 transition duration-200 ease-in-out mb-2"
            >
                View Profile
            </NavLink>

            {/* Logout Button */}
            <button
                onClick={onLogout}
                className="w-full bg-red-600 text-white py-2 rounded-lg hover:bg-red-700 transition duration-200 ease-in-out"
            >
                Logout
            </button>
        </div>
    );
};

export default ProfileDropdown;
