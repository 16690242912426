import React from "react";
import columnLeft from "../../../Assets/Images/Trend/columnLeft.png";
import columnCenter from "../../../Assets/Images/Trend/columnCenter.png";
import columnRight from "../../../Assets/Images/Trend/columnRight.png";
import TrendStars from "../../../Assets/Images/TrendStars.png";

const Designfabrics = () => {
    return (
        <section className="min-h-screen bg-gradient-to-b from-[#FDFCFB] to-[#E2D1C3] p-4 lg:p-8 flex flex-col items-center justify-center">
            {/* Main Header */}
            <h1 className="text-[25px] md:text-[28px] lg:text-[33px] text-center mb-12">
                <p className="text-black">We will provide the latest</p>
                <p className="text-[#B07641]">designs and fabrics</p>
            </h1>

            {/* Three Columns */}
            <div className="max-w-7xl w-full flex flex-col gap-8 md:grid md:grid-cols-3 md:gap-[3rem] lg:gap-[5rem] xl:gap-44 relative">
                {/* First Column */}
                <div className="flex flex-col items-center md:items-start text-center md:text-left">
                    <h2 className="hidden md:block text-[19px] 2xl:text-[24px] font-semibold text-[#B07641] mb-2">
                        Blue Stripe Bow Print
                    </h2>
                    <p className="hidden md:block text-[16px] 2xl:text-[20px] text-gray-700">
                        Precise DTF printing for a standout design that follows the latest trends
                    </p>
                    <img
                        src={columnLeft}
                        alt="Column 1"
                        className="w-full max-w-[309px] 2xl:w-[326px] h-auto lg:h-[290px] 2xl:h-[325px] rounded-lg mb-4"
                    />
                    <h2 className="block md:hidden text-[19px] 2xl:text-[24px] font-semibold text-[#B07641] mb-2">
                        Blue Stripe Bow Print
                    </h2>
                    <p className="block md:hidden text-[16px] 2xl:text-[20px] text-gray-700">
                        Precise DTF printing for a standout design that follows the latest trends
                    </p>
                </div>

                {/* Second Column */}
                <div className="flex flex-col items-center text-center">
                    <img
                        src={columnCenter}
                        alt="Column 2"
                        className="w-full max-w-[290px] 2xl:w-[326px] h-auto lg:h-[290px] 2xl:h-[325px] rounded-lg mb-4"
                    />
                    <h2 className="text-[19px] 2xl:text-[24px] font-semibold text-[#B07641] mb-2">
                        Blue Stripe Bow Print
                    </h2>
                    <p className="text-[16px] 2xl:text-[20px] text-gray-700">
                        Sublimation design offering rich, vibrant colors and long-lasting prints
                    </p>
                    <img
                        src={TrendStars}
                        alt="Trend Stars"
                        className="hidden lg:inline w-[40px] ml-2 object-contain absolute right-[380px] top-1/4"
                    />
                </div>

                {/* Third Column */}
                <div className="flex flex-col items-center md:items-start text-center md:text-left">
                    <h2 className="hidden md:block text-[19px] 2xl:text-[24px] font-semibold text-[#B07641] mb-2">
                        Blue Stripe Bow Print
                    </h2>
                    <p className="hidden md:block text-[16px] 2xl:text-[20px] text-gray-700">
                        A modern design that reflects the latest fashion trends
                    </p>
                    <img
                        src={columnRight}
                        alt="Column 3"
                        className="w-full max-w-[309px] 2xl:w-[326px] h-auto lg:h-[285px] 2xl:h-[325px] rounded-lg mb-4"
                    />
                    <h2 className="block md:hidden text-[19px] 2xl:text-[24px] font-semibold text-[#B07641] mb-2">
                        Blue Stripe Bow Print
                    </h2>
                    <p className="block md:hidden text-[16px] 2xl:text-[20px] text-gray-700">
                        A modern design that reflects the latest fashion trends
                    </p>
                </div>
            </div>
        </section>
    );
};

export default Designfabrics;
