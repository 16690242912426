import React from "react";

const Dividor = ({ width }) => {
  return (
    <div className="flex justify-center items-center my-2">
      <div className={`${width} h-[5px] bg-[#B07641] rounded-[6px]`} />
    </div>
  );
};

export default Dividor;
