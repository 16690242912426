import React from "react";
import BestFashion from "../../../Assets/Images/Trend/BestFashion.png";

const BestFashionDesign = () => {
    return (
        <section className="min-h-screen flex flex-col md:flex-row items-center justify-between gap-8 px-6 py-12 bg-gradient-to-b from-[#FDFCFB] to-[#E2D1C3]">
            {/* Left Column */}
            <div className="flex-1 text-center md:text-left mx-auto 2xl:mx-12">
                <div className="container w-[512px] 2xl:w-full mx-auto">
                    <h2 className="text-[35px] md:text-4xl text-gray-800 mb-4">
                        BEST FASHION DESIGN<br /> IN <span className="text-[#B07641]">2025</span>
                    </h2>
                    <br />
                    <p className="text-[19px] 2xl:text-[25px]  text-justify text-gray-600 mb-6">
                    Discover the latest fashion designs for 2025 in our section dedicated to the best trendy designs. We offer a collection of innovative fabrics and advanced printing techniques that reflect this year's popular trends. Whether you're looking for a bold look or an elegant design, you'll find the best options here that combine style and modernity, perfectly tailored to your taste and in line with the latest fashion trends.
                    </p>
                    <br />
                    <div className="pt-[4px] w-[160px] border border-[#B07641] rounded-br-[30px] rounded-tl-[30px]">
                        <button className="text-[12px] bg-[#B07641] text-white text-lg py-3 px-8 rounded-br-[30px] rounded-tl-[30px] shadow-lg hover:bg-[#cd8542]">
                            All Design
                        </button>
                    </div>
                </div>
            </div>

            {/* Right Column */}
            <div className="flex-1 flex justify-center items-center">
                <img
                    src={BestFashion}
                    alt="Fashion Design"
                    className="w-[450px] 2xl:w-[475px] h-[470px] 2xl:h-[500px] object-cover"
                />
            </div>
        </section>
    );
};

export default BestFashionDesign;
