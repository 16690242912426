import React from 'react';
import ShoppingCart from '../Components/Opal/ShoppingCart/MainShopCart';

const ShoppingCartPage = () => {
  return (
    <ShoppingCart />
  );
};

export default ShoppingCartPage;
