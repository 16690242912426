import React, { useState } from "react";
import './Payment.module.css'

const PaymentForm = () => {
    const [selectedMethod, setSelectedMethod] = useState("visa");

    const paymentMethods = [
        {
            id: "visa",
            name: "Visa ending in 7658",
            expiry: "Expiry 10/2024",
            logo: "https://upload.wikimedia.org/wikipedia/commons/5/5e/Visa_Inc._logo.svg",
        },
        {
            id: "mastercard",
            name: "Mastercard ending in 8429",
            expiry: "Expiry 04/2026",
            logo: "https://upload.wikimedia.org/wikipedia/commons/2/2a/Mastercard-logo.svg",
        },
        {
            id: "paypal",
            name: "Paypal account",
            expiry: "",
            logo: "https://upload.wikimedia.org/wikipedia/commons/b/b5/PayPal.svg",
        },
    ];

    return (
        <div className="max-w-xl mx-auto p-6 bg-white border rounded-md shadow-md mt-4">
            {/* Payment Method Tabs */}
            <div className="flex space-x-4 border-b pb-4 mb-6">
                {["Credit card", "Crypto", "Paypal"].map((tab) => (
                    <button
                        key={tab}
                        className="px-4 py-2 text-sm font-medium text-gray-500 hover:text-blue-600 border-b-2 border-transparent hover:border-blue-600 focus:border-blue-600"
                    >
                        {tab}
                    </button>
                ))}
            </div>

            {/* Payment Options */}
            <div className="space-y-4">
                {paymentMethods.map((method) => (
                    <div
                        key={method.id}
                        className={`flex items-start p-4 border rounded-md ${selectedMethod === method.id ? "border-blue-600" : "border-gray-300"
                            }`}
                    >
                        <input
                            type="radio"
                            name="payment"
                            id={method.id}
                            value={method.id}
                            checked={selectedMethod === method.id}
                            onChange={() => setSelectedMethod(method.id)}
                            className="w-4 h-4 mt-1 text-blue-600 focus:ring-blue-600 peer-checked:bg-blue-600"
                        />
                        <label htmlFor={method.id} className="flex-1 ml-4">
                            <div className="flex justify-between items-center">
                                <div>
                                    <p className="font-medium text-gray-900">{method.name}</p>
                                    {method.expiry && (
                                        <p className="text-sm text-gray-500">{method.expiry}</p>
                                    )}
                                    <div className="mt-2 flex space-x-2 text-sm text-gray-500">
                                        <button type="button">
                                            Delete
                                        </button>
                                        <div>|</div>
                                        <button type="button">
                                            Edit
                                        </button>
                                    </div>
                                </div>
                                <img
                                    src={method.logo}
                                    alt={method.name}
                                    className="h-8 w-auto"
                                />
                            </div>
                            {/* Edit and Delete Buttons */}
                        </label>
                    </div>
                ))}
            </div>

            {/* Add Payment Method */}
            <div className="mt-4">
                <label className="flex items-center">
                    <input
                        type="checkbox"
                        className="w-4 h-4 text-blue-600 focus:ring-blue-600"
                    />
                    <span className="ml-2 text-sm text-gray-900">Add new payment method</span>
                </label>
            </div>

            {/* Pay Now Button */}
            <div className="mt-6">
                <button className="w-full py-3 text-white bg-blue-600 rounded-md hover:bg-blue-700">
                    Pay now
                </button>
            </div>

            {/* Footer */}
            <div className="mt-6 text-sm text-gray-500 text-center">
                Payment processed by{" "}
                <button href="#" className="text-blue-600 hover:underline">
                    Paddle
                </button>{" "}
                for{" "}
                <button href="#" className="text-blue-600 hover:underline">
                    Flowbite LLC
                </button>{" "}
                - United States Of America
            </div>
        </div>
    );
};

export default PaymentForm;
