import React, { useState } from "react";
import VerifyModal from "../../Auth/VerifyModal";

function MainContent() {
    const userName = localStorage.getItem('userName');
    const userEmail = localStorage.getItem('userEmail');
    const verify = JSON.parse(localStorage.getItem('verify'));

    const [firstName, ...lastNameParts] = userName?.split(" ") || [];
    const lastName = lastNameParts.join(" ");
  
    const [isVerifyModalOpen, setVerifyModalOpen] = useState(false);

    const openVerifyModal = () => {
        setVerifyModalOpen(true);
    };

    const closeVerifyModal = () => {
        setVerifyModalOpen(false);
    };

    const handleVerifySuccess = () => {
        // Update the verify status after successful verification
        localStorage.setItem('verify', JSON.stringify(true));
        setVerifyModalOpen(false);
    };

    return (
        <div className="w-full lg:w-[55%] h-full p-6 md:p-8 shadow-xl rounded-xl border-t-2 border-gray-100 bg-white lg:mr-6">
            <h1 className="text-lg md:text-2xl font-bold mb-6">Personal Information</h1>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div>
                    <label className="block text-gray-600 mb-2">First Name</label>
                    <input
                        type="text"
                        className="w-full p-2 bg-gray-100 border rounded focus:outline-none"
                        value={firstName || ""}
                        readOnly
                    />
                </div>
                <div>
                    <label className="block text-gray-600 mb-2">Last Name</label>
                    <input
                        type="text"
                        className="w-full p-2 bg-gray-100 border rounded focus:outline-none"
                        value={lastName || ""}
                        readOnly
                    />
                </div>
                <div className="col-span-1 md:col-span-2">
                    <label className="block text-gray-600 mb-2">Email</label>
                    <div className="flex items-center rounded">
                        <input
                            type="email"
                            className="w-full p-2 bg-gray-100 border rounded-l-md focus:outline-none"
                            value={userEmail || ""}
                            readOnly
                        />
                        <button
                            onClick={!verify ? openVerifyModal : undefined}
                            className={`px-3 rounded-r-md text-[12px] text-center ${
                                verify
                                    ? "bg-green-200 text-green-800 py-[12px] cursor-not-allowed"
                                    : "bg-red-200 text-red-800 py-[3px] cursor-pointer hover:bg-red-300"
                            }`}
                        >
                            {verify ? "Verified" : "Not Verified"}
                        </button>
                    </div>
                </div>
                <div className="col-span-1 md:col-span-2">
                    <label className="block text-gray-600 mb-2">Address</label>
                    <input
                        type="text"
                        className="w-full p-2 bg-gray-100 border rounded focus:outline-none"
                        placeholder="Add your address"
                    />
                </div>
                <div>
                    <label className="block text-gray-600 mb-2">Phone Number</label>
                    <input
                        type="text"
                        className="w-full p-2 bg-gray-100 border rounded focus:outline-none"
                        placeholder="Add your phone number"
                    />
                </div>
                <div>
                    <label className="block text-gray-600 mb-2">Location</label>
                    <input
                        type="text"
                        className="w-full p-2 bg-gray-100 border rounded focus:outline-none"
                        placeholder="Add your location"
                    />
                </div>
            </div>
            <div className="flex mt-6">
                <button className="px-4 md:px-6 py-2 border border-[#d8ab82] text-black rounded transition">
                    Discard Changes
                </button>
                <button className="ml-4 px-4 md:px-6 py-2 bg-[#d8ab82] text-white rounded hover:bg-[#ce8d50] transition">
                    Save Changes
                </button>
            </div>

            {/* Verify Modal */}
            <VerifyModal
                isOpen={isVerifyModalOpen}
                onClose={closeVerifyModal}
                onVerifySuccess={handleVerifySuccess}
            />
        </div>
    );
}

export default MainContent;
