import React from "react";
import TrendBottomLeft from '../../../Assets/Images/Trend/TrendBottomLeft.png';
import TrendBottomRight from '../../../Assets/Images/Trend/TrendBottomRight.png';
import TrendTopLeft from '../../../Assets/Images/Trend/TrendTopLeft.png';
import TrendTopRight from '../../../Assets/Images/Trend/TrendTopRight.png';

const TrendSection = () => {
  const images = [
    TrendTopLeft,
    TrendTopRight,
    TrendBottomLeft,
    TrendBottomRight,
  ];

  return (
    <section className="h-[450px] md:h-[450px] xl:h-[500px] flex items-center justify-center bg-gradient-to-b from-[#FDFCFB] to-[#E2D1C3] p-8 overflow-visible relative">
      <div className="max-w-7xl w-full flex flex-col md:flex-row gap-12">
        {/* Left Section - Image Grid */}
        <div className="flex flex-col gap-2 flex-[1.5] relative mt-0 md:-mt-12">
          <div className="flex gap-1 translate-y-[4rem] md:translate-y-3">
            {/* Left Column */}
            <div className="flex flex-col gap-2">
              <img
                src={images[0]}
                alt="Design 1"
                className="w-[217px] lg:h-[230px] xl:h-[282px] object-cover rounded-tl-[40px] -translate-y-8 z-10"
              />
              <svg viewBox="0 0 209 282" fill="none" xmlns="http://www.w3.org/2000/svg" className="absolute -translate-y-10 z-0 w-[117px] md:w-[137px] lg:w-[191px] xl:w-[217px] md:h-[171px] lg:h-[239px] xl:h-[282px]">
                <path d="M0.5 100C0.5 45.0477 45.0477 0.5 100 0.5H208.5V281.5H0.5V100Z" stroke="#B07641" />
              </svg>
              <img
                src={images[2]}
                alt="Design 3"
                className="w-[217px] lg:h-[170px] xl:h-[205px] object-cover -translate-y-8"
              />
            </div>
            {/* Right Column */}
            <div className="flex flex-col gap-2">
              <img
                src={images[1]}
                alt="Design 2"
                className="w-[458px] lg:h-[195px] xl:h-[243px] object-cover -translate-y-8"
              />
              <div className="relative">
                <img
                  src={images[3]}
                  alt="Design 4"
                  className="absolute w-[455px] h-[125px] md:h-[140px] lg:h-[206px] xl:h-[244px] object-cover rounded-br-[40px] -translate-y-8 z-20"
                />
                <svg viewBox="0 0 462 243" fill="none" xmlns="http://www.w3.org/2000/svg" className="absolute translate-x-1 -translate-y-8 lg:w-[383px] xl:w-[455px] lg:h-[215px] xl:h-[243px] z-0">
                  <path d="M0.5 0.5H461.5V143C461.5 197.952 416.952 242.5 362 242.5H0.5V0.5Z" stroke="#B07641" />
                </svg>
              </div>
            </div>
          </div>
        </div>

        {/* Right Section - Text */}
        <div className="flex flex-col flex-[1] items-center justify-center text-center mb-16">
          <h2 className="text-[21px] lg:text-[34px] xl:text-[37px] font-semibold text-[#B07641] mb-4 text-shadow">TREND</h2>
          <p className="text-[17px] lg:text-[27px] xl:text-[30px] text-gray-700 leading-relaxed">
            Choose your favorite fabrics from the most trending designs that
            capture attention
          </p>
        </div>
      </div>
    </section >
  );
};

export default TrendSection;
