import React from "react";
import { NavLink } from "react-router-dom";

const MobileSubMenu = ({ isOpen, onClose }) => {
    // Define specific paths for each item
    const linkMap = {
        All: "/studio/floral",
        Floral: "/studio/floral",
        Ethnic: "/studio/floral",
        Border: "/studio/floral",
        Geometric: "/studio/floral",
        Texture: "/studio/floral",
        Animals: "/studio/floral",
        Dots: "/studio/floral",
        Tartan: "/studio/floral",
        Cartoon: "/studio/floral",
        Space: "/studio/floral",
        DTF: "/studio/floral"
    };

    return (
        <>
            {/* Overlay with opacity for closing submenu when clicking outside */}
            <div
                className={`fixed inset-0 bg-transparent ${isOpen ? "opacity-50" : "opacity-0 pointer-events-none"} transition-opacity duration-300`}
                onClick={onClose}
            ></div>

            {/* Submenu content */}
            <div
                className={`fixed top-0 right-0 h-full w-[65%] md:w-[40%] bg-white shadow-lg z-50 flex flex-col transition-transform duration-300 ease-in-out overflow-y-auto ${
                    isOpen ? "translate-x-0" : "translate-x-full"
                }`}
            >
                <button
                    onClick={onClose}  // Close submenu on click
                    className="relative py-6 px-6 text-lg border-b border-gray-300 flex items-center justify-center"
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="w-5 h-5 text-gray-500 absolute left-6"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    >
                        <path d="M14 7L9 12L14 17" />
                    </svg>
                    <span>Designs</span>
                </button>

                {/* Sub-menu items as navigation links */}
                {Object.entries(linkMap).map(([item, path]) => (
                    <NavLink
                        key={item}
                        to={path} // Link to specific paths
                        className="py-6 px-6 text-left text-md hover:bg-gray-200 border-b border-gray-300"
                        onClick={onClose} // Close both menus on link click
                    >
                        {item}
                    </NavLink>
                ))}
            </div>
        </>
    );
};

export default MobileSubMenu;
