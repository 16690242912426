import React from 'react';
import Banner from '../Components/Opal/Trend/Banner';
import Trend from '../Components/Opal/Trend/Trend';
import Designfabrics from '../Components/Opal/Trend/Design&fabrics';
import ModernDesign from '../Components/Opal/Trend/ModernDesign';
import BestFashionDesign from '../Components/Opal/Trend/BestFashionDesign';
import ExclusiveCollection from '../Components/Opal/Trend/ExclusiveCollection';
import ImageSlider from '../Components/Opal/Trend/ImageSlider';


const TrendPage = () => {
    return (
        <div className='overflow-hidden'>
            <Banner />
            <Trend />
            <Designfabrics />
            <ModernDesign />
            <BestFashionDesign />
            <ExclusiveCollection />
            <ImageSlider />
            <section className="flex flex-col items-center justify-center my-4 py-12 bg-gradient-to-b from-white to-[#f2e4d8]">
                {/* Title */}
                <h2 className="text-lg md:text-2xl font-semibold text-gray-800 mb-2">
                    LET’S TALK FOR A NEW DESIGN
                </h2>
                {/* Subtitle */}
                <p className="text-sm md:text-base text-gray-600 mb-6">
                    Got an innovative design in mind?Contact us now to start creating your custom design
                </p>
                {/* Button */}
                <button className="bg-black text-white px-8 py-3 rounded-full shadow-md hover:scale-105 transition-transform">
                    Contact US
                </button>
            </section>
        </div>
    );
};

export default TrendPage;
