import React from "react";

const CloseButton = ({ onClose }) => (
  <button
    onClick={onClose}
    className="absolute top-2 right-2 text-gray-400 hover:text-gray-600 text-2xl"
  >
    &times;
  </button>
);

export default CloseButton;
