import React, { useState } from "react";
import VerifyModal from "./VerifyModal";
import CloseButton from "../Common/Button/CloseButton";
import AuthHeader from "./AuthHeader";
import AuthForm from "./AuthForm";
import AuthFooter from "./AuthFooter";
import Toaster from "../Feedback/ToasterAuth";
import { useToaster } from "../../contexts/ToasterContext";

const AuthModal = ({ isOpen, onClose }) => {
  const [isLogin, setIsLogin] = useState(true);
  const [showVerifyModal, setShowVerifyModal] = useState(false);
  const { toaster, showToaster } = useToaster();

  const handleAuthSuccess = () => {
    const verify = localStorage.getItem("verify") === "true";
    if (!verify) {
      setShowVerifyModal(true);
    } else {
      setTimeout(() => onClose(), 1000);
    }
  };

  const handleVerifySuccess = () => {
    setShowVerifyModal(false); // Close VerifyModal
    onClose(); // Close AuthModal
  };

  if (!isOpen) return null;

  return (
    <>
      <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-50 px-4 sm:px-6">
        <div className="relative w-full max-w-md sm:max-w-lg bg-white rounded-lg shadow-lg p-4 sm:p-6">
          <Toaster message={toaster?.message} type={toaster?.type} />
          <CloseButton onClose={onClose} />
          <AuthHeader isLogin={isLogin} />
          <AuthForm
            isLogin={isLogin}
            onClose={onClose}
            onAuthSuccess={handleAuthSuccess}
            setToaster={showToaster}
          />
          <AuthFooter isLogin={isLogin} onToggle={() => setIsLogin(!isLogin)} />
        </div>
      </div>
      <VerifyModal
        isOpen={showVerifyModal}
        onClose={() => {
          setShowVerifyModal(false); // Close VerifyModal
          onClose(); // Close AuthModal
        }}
        onVerifySuccess={handleVerifySuccess}
      />
    </>
  );
};

export default AuthModal;
