import React, { createContext, useContext, useState } from "react";

const ToasterContext = createContext();

export const ToasterProvider = ({ children }) => {
  const [toaster, setToaster] = useState(null);

  const showToaster = (newToaster) => {
    setToaster(newToaster);
    setTimeout(() => setToaster(null), 1000);
  };

  return (
    <ToasterContext.Provider value={{ toaster, showToaster }}>
      {children}
    </ToasterContext.Provider>
  );
};

export const useToaster = () => useContext(ToasterContext);
