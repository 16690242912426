import React from "react";
import Image1 from "../../../Assets/Images/Trend/ExColl1.png";
import Image2 from "../../../Assets/Images/Trend/ExColl2.png";
import Image3 from "../../../Assets/Images/Trend/ExColl3.png";
import Image4 from "../../../Assets/Images/Trend/ExColl4.png";
import TrendStars from "../../../Assets/Images/TrendStars.png";

const ExclusiveCollection = () => {
    return (
        <section className="h-[550px] hidden lg:flex flex-col md:flex-row items-center justify-between gap-8 px-6 py-12 bg-white overflow-visible relative">
            {/* Left Column */}
            <div className="flex-1 text-center md:text-left lg:px-[0px] 1340:px-[90px] relative -mt-12">
                <img
                    src={TrendStars}
                    alt="Trend Stars"
                    className="inline w-[40px] ml-2 object-contain absolute -top-4 right-20 lg:right-0 1340:right-[6rem] xl:right-[3rem] 2xl:right-32 3xl:right-[6rem]"
                />
                <div className="container">
                    <h2 className="text-[15px] md:text-[26px] 1340:text-[30px] xl:text-[30px] 3xl:text-[38px] text-gray-800 mb-4">
                        Check out our exclusive collection featuring the most <span className="text-[#B07641]">trending designs</span> this season!
                    </h2>
                    <br />
                    <p className="lg:text-[16px] 1340:text-[19px] 3xl:text-[23px] text-justify text-[#B8B8B8] mb-6">
                        we present the most prominent and latest fashion trends for 2025, featuring innovative designs that combine style and comfort. Here, you will discover collections of trendy fabrics that suit all tastes, whether you're looking for everyday wear or special designs for events. Stay up to date with the latest fashion trends and get inspired with fresh ideas for your own designs that blend quality and creativity.
                    </p>
                </div>
            </div>

            {/* Right Column */}
            <div className="flex-1 3xl:flex-[0.8] grid grid-cols-2 justify-items-center">
                <img
                    src={Image1}
                    alt="Fashion Design"
                    className="lg:w-[200px] 1340:w-[210px] xl:w-[250px] lg:h-[200px] 1340:h-[210px] xl:h-[250px] object-cover rounded-lg -translate-y-16"
                />
                <img
                    src={Image2}
                    alt="Fashion Design"
                    className="lg:w-[200px] 1340:w-[210px] xl:w-[250px] lg:h-[200px] 1340:h-[210px] xl:h-[250px] object-cover rounded-lg -translate-y-24 lg:-translate-x-6 1340:-translate-x-12"
                />
                <img
                    src={Image3}
                    alt="Fashion Design"
                    className="lg:w-[200px] 1340:w-[210px] xl:w-[250px] lg:h-[200px] 1340:h-[210px] xl:h-[250px] object-cover rounded-lg -translate-y-12"
                />
                <img
                    src={Image4}
                    alt="Fashion Design"
                    className="lg:w-[200px] 1340:w-[210px] xl:w-[250px] lg:h-[200px] 1340:h-[210px] xl:h-[250px] object-cover rounded-lg -translate-y-20 lg:-translate-x-6 1340:-translate-x-12"
                />
            </div>
        </section>
    );
};

export default ExclusiveCollection;
