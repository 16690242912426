import React, { useState } from "react";
import { useAuth } from "../../contexts/AuthContext";
import OrDivider from "../Common/OrDividor";
import GoogleButton from "../Common/Button/GoogleButton";

const AuthForm = ({ isLogin, onClose, onAuthSuccess, setToaster, onForgotPassword }) => {
  const { handleRegister, handleLogin } = useAuth();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    password_confirmation: "",
  });
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const message = isLogin
        ? await handleLogin(formData)
        : await handleRegister(formData);
      if (!isLogin) {
        onAuthSuccess();
      } else {
        setTimeout(() => onClose(), 1000);
        setToaster({ type: "success", message });
      }
    } catch (error) {
      setToaster({
        type: "error",
        message: error.message || "An error occurred",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <form className="space-y-4" onSubmit={handleSubmit}>
      {!isLogin && (
        <InputField
          label="Name"
          name="name"
          type="text"
          placeholder="Enter your name"
          value={formData.name}
          onChange={handleChange}
        />
      )}
      <InputField
        label="Email"
        name="email"
        type="email"
        placeholder="Enter your email"
        value={formData.email}
        onChange={handleChange}
      />
      <InputField
        label="Password"
        name="password"
        type="password"
        placeholder="Enter your password"
        value={formData.password}
        onChange={handleChange}
      />
      {isLogin && (
        <div className="text-right">
          <button
            type="button"
            className="text-sm text-indigo-600 hover:underline focus:outline-none"
            onClick={onForgotPassword}
          >
            Forgot Password?
          </button>
        </div>
      )}
      {!isLogin && (
        <InputField
          label="Confirm Password"
          name="password_confirmation"
          type="password"
          placeholder="Rewrite your password"
          value={formData.password_confirmation}
          onChange={handleChange}
        />
      )}
      <button
        type="submit"
        className="w-full bg-indigo-600 text-white py-2 rounded-lg hover:bg-indigo-700 transition"
        disabled={loading}
      >
        {loading ? "Processing..." : isLogin ? "Login" : "Register"}
      </button>
      {isLogin && (
        <>
          <OrDivider />
          <GoogleButton />
        </>
      )}
    </form>
  );
};

const InputField = ({ label, name, type, placeholder, value, onChange }) => (
  <div>
    <label className="block text-sm font-medium text-gray-700 mb-1">
      {label}
    </label>
    <input
      name={name}
      type={type}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      className="w-full px-4 py-2 border rounded-lg text-sm focus:ring-2 focus:ring-indigo-500 focus:outline-none"
    />
  </div>
);

export default AuthForm;
