import axios from "axios";

const API_URL = "http://127.0.0.1:8000";

// Verify API call
export const verifyCode = async (verificationCode) => {
    try {
        const token = localStorage.getItem('authToken');
        const response = await axios.post(`${API_URL}/v1/auth/verify`, {
            verification_code: verificationCode,
        },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        localStorage.setItem('verify', true);
        return response.data;
    } catch (error) {
        throw error.response ? error.response.data : { message: "Verification failed" };
    }
};

// Reverify API call
export const resendVerificationCode = async () => {
    try {
        const token = localStorage.getItem('authToken');
        const response = await axios.post(`${API_URL}/v1/auth/reverify`,
            {},
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        return response.data;
    } catch (error) {
        throw error.response ? error.response.data : { message: "Resending code failed" };
    }
};
