import React from "react";
import Dividor from "../dividor";
import FrameLeft from "../../../Assets/Images/Home/Frame 12.webp";
import FrameRight from "../../../Assets/Images/Home/Frame 13.webp";
import TrendStars from "../../../Assets/Images/TrendStars.png";

const AboutUsSection = () => {
  return (
    <section className="sm:hidden md:block lg:block xl:block 2xl:block min-h-screen bg-gradient-to-b from-[#FDFCFB] to-[#E2D1C3] py-8 px-4 md:px-8 overflow-hidden">
      <div className="flex items-end justify-end">
        <div className="text-end">
          <h3 className="text-5xl text-[#B07641] opacity-20 translate-x-10 mb-4">PRINT</h3>
        </div>
      </div>
      <div className="max-w-6xl mx-auto grid grid-cols-1 md:grid-cols-2 md:gap-[48px] lg:gap-[200px] xl:gap-[256px] 2xl:gap-[256px] relative">
        {/* Left Column */}
        <div className="flex flex-col items-center md:items-start text-center md:text-left mx-auto">
          <div className="mb-4">
            <h4 className="text-2xl md:text-3xl 2xl:text-3xl font-bold">About Us</h4>
            <Dividor width="lg:w-[130px] xl:w-[120px]" />
          </div>
          <h4 className="md:text-lg lg:text-lg xl:text-xl 2xl:text-2xl font-bold mb-4">From fabrics to designs, we help bring your ideas to life with functional and artistic pieces</h4>
          <p className="md:text-md lg:text- xl:text-md 2xl:text-lg text-gray-600 mb-6">
            This is a paragraph in the left column. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
          </p>
          <img
            src={FrameLeft}
            alt="Left Column"
            className="object-cover mx-auto 2xl:w-full"
            loading="lazy"
          />
        </div>

        <img
          src={TrendStars}
          alt="Trend Stars"
          className="md:hidden lg:inline xl:inline w-[40px] ml-2 object-contain absolute top-[11.55rem] right-1/2"
          loading="lazy"
        />


        {/* Right Column */}
        <div className="flex flex-col items-center md:items-start text-center md:text-left">
          <img
            src={FrameRight}
            alt="Right Column"
            className="object-cover mb-6 2xl:w-[316px]"
            loading="lazy"
          />
          <h2 className="md:text-lg lg:text-lg xl:text-xl 2xl:text-2xl font-bold mb-4">From fabrics to designs, we help bring your ideas to life with functional and artistic pieces</h2>
          <p className="md:text-md lg:text- xl:text-md 2xl:text-lg text-gray-600">
            This is a paragraph in the right column. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
          </p>
        </div>
      </div>
    </section>
  );
};

export default AboutUsSection;
