import React, { useState } from "react";
import MobileSubMenu from "./MobileSubMenu";
import { NavLink } from "react-router-dom";

const MobileMenu = ({ isOpen, onClose }) => {
    const [showSubMenu, setShowSubMenu] = useState(false);

    const handleDesignClick = () => {
        setShowSubMenu(prev => !prev); // Toggle submenu visibility
    };

    const closeAllMenus = () => {
        onClose(); // Close main menu
        setShowSubMenu(false); // Close submenu
    };

    return (
        <>
            {/* Overlay with opacity */}
            <div
                className={`fixed inset-0 bg-black ${isOpen ? "opacity-50" : "opacity-0 pointer-events-none"} transition-opacity duration-300`}
                onClick={closeAllMenus}
            ></div>

            {/* Main Mobile Menu */}
            <div
                className={`mobile-menu ${isOpen ? "translate-x-0" : "translate-x-full"} fixed top-0 right-0 h-full w-[65%] md:w-[40%] bg-white shadow-lg z-40 flex flex-col transition-transform duration-300`}
            >
                {/* Main Menu Items */}
                <NavLink
                    to="/studio/floral"
                    onClick={handleDesignClick}
                    className="py-6 px-6 text-left text-lg border-b border-gray-300 flex items-center justify-between"
                >
                    <span>Designs</span>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="w-5 h-5 text-gray-500"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    >
                        <path d="M9 18l6-6-6-6" />
                    </svg>
                </NavLink>
                <NavLink to="/trends" onClick={closeAllMenus} className="py-6 px-6 text-left text-lg hover:bg-gray-200 border-b border-gray-300">
                    Trends
                </NavLink>
                <NavLink
                    to="/about"
                    onClick={closeAllMenus}
                    className="py-6 px-6 text-left text-lg hover:bg-gray-200 border-b border-gray-300"
                >
                    About Us
                </NavLink>
                <button onClick={closeAllMenus} className="py-6 px-6 text-center text-lg hover:bg-gray-200 border-b border-gray-300">
                    Join Now
                </button>
                <button onClick={closeAllMenus} className="py-6 px-6 text-center text-lg hover:bg-gray-200 border-b border-gray-300">
                    Sign In
                </button>
            </div>

            {/* Mobile SubMenu, controlled by `showSubMenu` */}
            <MobileSubMenu isOpen={showSubMenu} onClose={closeAllMenus} />
        </>
    );
};

export default MobileMenu;
