import React, { useState, useEffect } from "react";
import { useAuth } from "../../contexts/AuthContext";

const VerifyModal = ({ isOpen, onClose, onVerifySuccess }) => {
    const [verificationCode, setVerificationCode] = useState("");
    const [loading, setLoading] = useState(false);
    const [timer, setTimer] = useState(0); // Timer for the countdown
    const { handleVerify, handleResendCode, authError } = useAuth();

    const handleVerification = async () => {
        setLoading(true);
        try {
            await handleVerify(verificationCode);
            onClose();
            if (onVerifySuccess) {
                onVerifySuccess();
            }
        } catch (error) {
            console.error(error.message || "Verification failed");
        } finally {
            setLoading(false);
        }
    };

    const resendCode = async () => {
        try {
            await handleResendCode();
            setTimer(30); // Start the 30-second timer after resending
        } catch (error) {
            console.error(error.message || "Failed to resend code");
        }
    };

    useEffect(() => {
        if (timer > 0) {
            const interval = setInterval(() => {
                setTimer((prev) => prev - 1);
            }, 1000);
            return () => clearInterval(interval);
        }
    }, [timer]);

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-50 px-4 sm:px-6">
            <div className="relative w-full max-w-md bg-white rounded-lg shadow-lg p-6 space-y-4">
                <h2 className="text-lg font-semibold text-gray-900 text-center">
                    Verify Your Account
                </h2>
                <p className="text-sm text-gray-600 text-center">
                    Enter the verification code sent to your email.
                    <span className="inline text-sm text-gray-500 ml-2">
                        <button
                            onClick={onClose}
                            className="text-indigo-600 hover:underline focus:outline-none"
                        >
                            Verify Later
                        </button>
                    </span>
                </p>
                <input
                    type="text"
                    placeholder="Enter verification code"
                    value={verificationCode}
                    onChange={(e) => setVerificationCode(e.target.value)}
                    className="w-full px-4 py-2 border rounded-lg text-sm focus:ring-2 focus:ring-indigo-500 focus:outline-none"
                />
                {authError && <p className="text-red-600 text-sm">{authError}</p>}
                <button
                    onClick={handleVerification}
                    disabled={loading}
                    className={`w-full py-2 rounded-lg ${loading
                        ? "bg-gray-400"
                        : "bg-indigo-600 hover:bg-indigo-700 text-white"
                        } transition`}
                >
                    {loading ? "Verifying..." : "Verify"}
                </button>
                <p className="text-sm text-gray-500 text-center">
                    Didn't receive the code?
                    <div className="inline-block ml-1 items-center justify-center mt-2">
                        {timer > 0 ? (
                            <span className="text-sm text-gray-500 font-medium">
                                Resend available in{" "}
                                <span className="text-indigo-600 font-semibold">
                                    {timer}s
                                </span>
                            </span>
                        ) : (
                            <button
                                onClick={resendCode}
                                className="inline-block text-sm text-indigo-600 hover:underline focus:outline-none"
                            >
                                Resend Code
                            </button>
                        )}
                    </div>
                </p>
            </div>
        </div>
    );
};

export default VerifyModal;
