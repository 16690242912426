import React from "react";
import useGoogleLogin from "../../../Hooks/useGoogleLogin";

const GoogleButton = () => {
  const { loginWithGoogle, loading } = useGoogleLogin();

  return (
    <button
      type="button"
      className={`w-full flex items-center justify-center space-x-2 border border-gray-300 py-2 rounded-lg transition ${
        loading ? "bg-gray-200 cursor-not-allowed" : "hover:bg-gray-100"
      }`}
      onClick={loginWithGoogle}
      disabled={loading}
    >
      <img
        src="https://www.svgrepo.com/show/475656/google-color.svg"
        alt="Google"
        className="w-5 h-5"
      />
      <span>{loading ? "Loading..." : "Login with Google"}</span>
    </button>
  );
};

export default GoogleButton;