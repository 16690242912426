import React from "react";
import {
  ModernDesignSvg1,
  ModernDesignSvg2,
  ButtonArrowSvg,
} from "../../../Assets/Svg/ModernDesign1";
import ModernDesign1 from "../../../Assets/Images/Trend/ModernDesign1.png";
import ModernDesign2 from "../../../Assets/Images/Trend/ModernDesign2.png";
import ModernDesign3 from "../../../Assets/Images/Trend/ModernDesign3.png";

const ModernDesignSection = () => {
  return (
    <div className="px-6 md:px-10 lg:px-16 2xl:px-[13rem] py-12">
      {/* Header and Title */}
      <div className="text-center mb-8 block xl:hidden">
        <h1 className="text-4xl md:text-5xl font-bold text-[#333] mb-4">Modern Design</h1>
        <p className="text-lg md:text-xl text-gray-600">
          Explore the latest trends in modern fashion and style.
        </p>
      </div>

      {/* Section Content */}
      <section className="flex flex-wrap lg:flex-nowrap items-center justify-center gap-4">
        {/* Left Section */}
        <div className="relative w-[681px] 2xl:w-[700px] xl:h-[569px] 2xl:h-[593px] rounded-lg overflow-hidden hidden xl:block">
          <ModernDesignSvg1 backgroundImage={ModernDesign1} />
          {/* Overlay */}
          <div className="w-[156px] h-[38px] absolute inset-0 bg-white rounded-full">
            <div className="w-[144px] h-[34px] rounded-full pr-4">
              <button className="w-[144px] h-[34px] bg-white text-black text-xs uppercase px-4 py-2 rounded-full shadow-md border border-[#B07641]">
                Explore Now
              </button>
            </div>
          </div>
          <div className="w-[177px] h-[60px] absolute top-[38px] inset-0 bg-white rounded-full flex items-center justify-start">
            <h1 className="text-black font-bold text-[32px]">MODERN</h1>
          </div>
          <div className="w-[202px] h-[59px] absolute top-[98px] inset-0 bg-white rounded-full flex items-center justify-start">
            <h1 className="text-black font-bold text-[32px]">DESIGN</h1>
          </div>

          {/* Button */}
          <div className="absolute bottom-0 right-0">
            <button className="bg-[#A67640] text-white w-[121px] h-[98px] rounded-[25px] flex items-center justify-center mb-2">
              <ButtonArrowSvg />
            </button>
          </div>
        </div>

        {/* Right Section */}
        <div className="flex flex-col w-[790px] 2xl:w-[800px] md:h-[516px] lg:h-[588px] xl:h-[569px] 2xl:h-[585px] gap-4">
          {/* Card Component */}
          {[
            {
              img: ModernDesign2,
              title: "Blue Stripe Bow Print",
            },
            {
              img: ModernDesign3,
              title: "Blue Stripe Bow Print",
            },
          ].map((item, index) => (
            <div
              key={index}
              className="w-full lg:h-[295px] xl:h-[266px] 2xl:h-[278px] 3xl:h-[278px] relative rounded-lg overflow-hidden"
            >
              <ModernDesignSvg2 backgroundImage={item.img} />
              <div className="absolute inset-0 flex justify-between items-end pl-4">
                <div className="w-32 text-white mb-28 lg:mb-36 ml-5">
                  <button className="text-xs py-2 px-6 block border border-[#B07641] bg-transparent rounded-full">Explore Now</button>
                  <h2 className="text-2xl mt-2">{item.title}</h2>
                </div>
                <button className="bg-[#A67640] text-white w-[121px] h-[88px] rounded-[25px] flex items-center justify-center mb-2">
                  <ButtonArrowSvg />
                </button>
              </div>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};

export default ModernDesignSection;
