import React from "react";

const AuthFooter = ({ isLogin, onToggle }) => (
  <p className="text-center text-sm text-gray-600 mt-4">
    {isLogin ? "Don't have an account?" : "Already have an account?"}{" "}
    <button
      onClick={onToggle}
      className="text-indigo-600 font-medium hover:underline"
    >
      {isLogin ? "Register" : "Login"}
    </button>
  </p>
);

export default AuthFooter;
