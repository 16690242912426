import React, { useState } from "react";
import CartItem from "./CartItem";
import PaymentForm from "../PaymentForm/PaymentForm";

const ShoppingCart = () => {
  const [showPaymentForm, setShowPaymentForm] = useState(false);

  const products = [
    {
      id: 1,
      name: "Basic Tee",
      price: 32.0,
      description: "By opg-3",
      availability: "Floral, Nature",
      image: "https://via.placeholder.com/150",
    },
    {
      id: 2,
      name: "Basic Tee",
      price: 32.0,
      description: "By opg-3",
      availability: "Floral, Nature",
      image: "https://via.placeholder.com/150",
    },
    {
      id: 3,
      name: "Nomad Tumbler",
      price: 35.0,
      description: "By opg-3",
      availability: "Floral, Nature",
      image: "https://via.placeholder.com/150",
    },
  ];

  return (
    <div className="bg-white my-20">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h1 className="text-3xl font-bold tracking-tight text-gray-900">
          Shopping Cart
        </h1>
        <div className="mt-8 grid grid-cols-1 lg:grid-cols-3 gap-12">
          {/* Items Section */}
          <div className="lg:col-span-2">
            <div className="divide-y divide-gray-200">
              {products.map((product) => (
                <CartItem key={product.id} product={product} />
              ))}
            </div>
          </div>

          {/* Summary Section */}
          <div>
            <div className="bg-gray-100 p-6 rounded-lg shadow">
              <h2 className="text-2xl font-medium text-gray-900">
                Summary
              </h2>
              <hr className="mt-4"/>
              <div className="mt-6 space-y-4">
                <div className="flex justify-between text-md text-gray-600">
                  <p>Total Products</p>
                  <p>$99.00</p>
                </div>
                <div className="flex justify-between text-md text-gray-600">
                  <p>Tax estimate</p>
                  <p>Free</p>
                </div>
                <hr />
                <div className="flex justify-between text-base font-medium text-gray-900">
                  <p>Totals</p>
                  <p>$112.32</p>
                </div>
              </div>
              
            </div>
            <div className="bg-gray-100 rounded-lg shadow">
                <button
                  type="button"
                  className="w-full bg-indigo-600 text-white py-3 px-4 rounded-md shadow hover:bg-indigo-700"
                  onClick={() => setShowPaymentForm(true)}
                >
                  Checkout
                </button>
              </div>
          </div>
        </div>
      </div>

      {/* Modal for Payment Form */}
      {showPaymentForm && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-lg relative">
            <button
              onClick={() => setShowPaymentForm(false)}
              className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
            >
              ✕
            </button>
            <PaymentForm />
          </div>
        </div>
      )}
    </div>
  );
};

export default ShoppingCart;
