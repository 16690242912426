import React from "react";
import LogoImg from "../../Assets/Images/opalLogo.png";
import { NavLink } from "react-router-dom";

const Logo = ({ alt = "Logo", width = 'w-[110px]', height = 'h-[83px]', TranslateX = 0 }) => (
    <NavLink to='/' className={`flex items-center pr-2 text-gray-700 hover:text-gray-900 ${width} ${width} ${TranslateX}`}>
        <img src={LogoImg} alt={alt} style={{ width, height }} loading="lazy" />
    </NavLink>
);

export default Logo;
