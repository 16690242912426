import React from "react";

export const ModernDesignSvg1 = ({ backgroundImage }) => (
  <svg
    width="632"
    height="549"
    viewBox="0 0 640 557"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="absolute inset-0 w-full h-full"
    style={{
      backgroundImage: `url(${backgroundImage})`,
      backgroundSize: "cover",
      backgroundPosition: "center",
    }}
  >
    <g filter="url(#filter0_d_134_2)">
      <path
        d="M49.7395 6.55949C48.6781 3.32606 51.0869 0 54.4901 0H616C627.046 0 636 8.9543 636 20V404.403C636 420.971 622.569 434.403 606 434.403H529.298C512.729 434.403 499.298 447.834 499.298 464.403V519C499.298 535.569 485.866 549 469.298 549H24C12.9543 549 4 540.046 4 529V183.684C4 167.116 17.4315 153.684 34 153.684H166.719C182.909 153.684 196.034 140.559 196.034 124.369C196.034 108.178 182.909 95.0534 166.719 95.0534H78.79L49.7395 6.55949Z"
        fill="transparent"
        shapeRendering="crispEdges"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_134_2"
        x="0"
        y="0"
        width="640"
        height="557"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_134_2"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_134_2"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

export const ModernDesignSvg2 = ({ backgroundImage }) => (
  <svg
    width="745"
    height="278"
    viewBox="0 0 753 272"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="w-full h-full object-cover"
    style={{
      backgroundImage: `url(${backgroundImage})`,
      backgroundSize: "cover",
      backgroundPosition: "center",
    }}
  >
    <g filter="url(#filter0_d_137_16)">
      <path
        d="M4 20C4 8.95431 12.9543 0 24 0H729C740.046 0 749 8.9543 749 20V133.322C749 149.891 735.569 163.322 719 163.322H645.149C628.58 163.322 615.149 176.753 615.149 193.322V234C615.149 250.569 601.718 264 585.149 264H24C12.9543 264 4 255.046 4 244V73.9029V45.7087V20Z"
        fill="transparent"
        shapeRendering="crispEdges"
      />
      <path
        d="M4 20C4 8.95431 12.9543 0 24 0H729C740.046 0 749 8.9543 749 20V133.322C749 149.891 735.569 163.322 719 163.322H645.149C628.58 163.322 615.149 176.753 615.149 193.322V234C615.149 250.569 601.718 264 585.149 264H24C12.9543 264 4 255.046 4 244V73.9029V45.7087V20Z"
        fill="black"
        fillOpacity="0.25"
        shapeRendering="crispEdges"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_137_16"
        x="0"
        y="0"
        width="753"
        height="272"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_137_16" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_137_16" result="shape" />
      </filter>
    </defs>
  </svg>
);

export const ButtonArrowSvg = () => (
  <svg
    viewBox="0 0 22 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="w-6 h-6"
  >
    <path
      d="M22 1.91868V18.5472C22 19.0561 21.817 19.5441 21.4914 19.9039C21.1657 20.2637 20.7241 20.4659 20.2635 20.4659C19.803 20.4659 19.3613 20.2637 19.0356 19.9039C18.71 19.5441 18.527 19.0561 18.527 18.5472V6.55548L2.96952 23.742C2.6433 24.1025 2.20085 24.305 1.7395 24.305C1.27816 24.305 0.835708 24.1025 0.509489 23.742C0.183269 23.3816 0 22.8927 0 22.383C0 21.8732 0.183269 21.3843 0.509489 21.0239L16.067 3.83735H5.21393C4.75338 3.83735 4.3117 3.63521 3.98605 3.27539C3.66039 2.91556 3.47744 2.42754 3.47744 1.91868C3.47744 1.40981 3.66039 0.921789 3.98605 0.561967C4.3117 0.202145 4.75338 0 5.21393 0H20.2635C20.7241 0 21.1657 0.202145 21.4914 0.561967C21.817 0.921789 22 1.40981 22 1.91868Z"
      fill="white"
    />
  </svg>
);
