import React from "react";
import { NavLink } from "react-router-dom";

const CartDropdown = ({ cartItems, totalPrice, cartDropdownRef }) => {
    return (
        <div
            ref={cartDropdownRef}
            className="absolute right-[-21px] top-[2rem] mt-2 w-64 border-2 border-black bg-white shadow-xl rounded-lg p-4 z-50"
        >
            <div
                className={`absolute top-[-8px] right-[55px] transform -translate-x-1/2 w-0 h-0 border-l-8 border-r-8 border-b-8 border-transparent border-b-black`}
            />
            <div className={`space-y-4 ${cartItems.length > 3 ? 'overflow-y-auto max-h-48' : ''}`}>
                {cartItems.map((item) => (
                    <div key={item.id} className="flex items-center space-x-3">
                        <img src={item.imageUrl} alt={item.title} className="w-12 h-12 rounded object-cover" />
                        <div>
                            <p className="font-semibold">{item.title}</p>
                            <p className="text-sm text-gray-500">{item.price.toFixed(2)}</p>
                        </div>
                    </div>
                ))}
            </div>
            <hr className="my-4" />
            <div className="flex justify-between items-center mb-[15px]">
                <div className="font-semibold text-left">Total:</div>
                <div className="font-semibold text-right">{totalPrice.toFixed(2)}</div>
            </div>
            <NavLink to="/ShoppingCart" className="bg-black text-white px-[4rem] py-[6px] mx-auto flex items-center justify-center rounded-lg hover:bg-gray-800 transition duration-200 ease-in-out ">
                View Bag
            </NavLink>
        </div>
    );
};

export default CartDropdown;
