import React from 'react';
import Frame15 from "../../../Assets/Images/Home/Frame 15.webp";
import Frame14 from "../../../Assets/Images/Home/Frame 14.webp";

const DigitalPrintingSection = () => {
  return (
    <div className="bg-[#E2D1C3] md:bg-white">
      <div className="w-full grid sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4 gap-6 items-center">
        {/* Left Image */}
        <div className="hidden items-center justify-center md:flex lg:flex">
          <img
            src={Frame15}
            alt="Fabric design"
            className="shadow-lg 2xl:w-3/4"
          />
        </div>

        {/* Content */}
        <div className="w-[93%] text-center mx-auto sm:col-span-1 md:col-span-2 py-8 md:py-0">
          <h1 className="sm:text-2xl md:text-xl lg:text-2xl xl:text-4xl 2xl:text-5xl text-gray-900 mb-2">
            HI! WE ARE{' '}
            <span className="text-[#B07641]">GROUP DIGITAL PRINTING!</span>
          </h1>
          <div className="w-[290px] sm:text-[19px] md:text-[19px] lg:text-[21px] xl:text-[21px] 2xl:text-[27px] font-bold text-gray-600 mx-auto lg:mb-2 xl:mb-2 2xl:mb-2 lg:mt-[0px] xl:mt-[48px] 2xl:mt-2">
            <svg viewBox="0 0 26 32" fill="none" xmlns="http://www.w3.org/2000/svg" className='mx-auto w-[20px] h-[26px] 2xl:w-[25px] 2xl:h-[31px]'>
              <g filter="url(#filter0_d_389_114)">
                <path d="M22 11.9876C22.002 12.339 21.9218 12.6825 21.7706 12.9704C21.6193 13.2583 21.4045 13.4763 21.156 13.594L16.0566 16.0647L14.203 22.8579C14.1116 23.1858 13.9471 23.4686 13.7316 23.6683C13.516 23.8681 13.2598 23.9751 12.9973 23.9751C12.7347 23.9751 12.4785 23.8681 12.2629 23.6683C12.0474 23.4686 11.8829 23.1858 11.7915 22.8579L9.93795 16.0637L4.83856 13.594C4.59243 13.4723 4.38015 13.2531 4.23025 12.9659C4.08035 12.6788 4 12.3374 4 11.9876C4 11.6378 4.08035 11.2963 4.23025 11.0092C4.38015 10.722 4.59243 10.5028 4.83856 10.3811L9.93795 7.91146L11.7915 1.11726C11.8829 0.789324 12.0474 0.506498 12.2629 0.306775C12.4785 0.107052 12.7347 0 12.9973 0C13.2598 0 13.516 0.107052 13.7316 0.306775C13.9471 0.506498 14.1116 0.789324 14.203 1.11726L16.0574 7.91146L21.156 10.3811C21.4045 10.4988 21.6193 10.7168 21.7706 11.0047C21.9218 11.2926 22.002 11.6361 22 11.9876Z" fill="#B07641" />
              </g>
              <defs>
                <filter id="filter0_d_389_114" x="0" y="0" width="26" height="31.9756" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                  <feFlood floodOpacity="0" result="BackgroundImageFix" />
                  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                  <feOffset dy="4" />
                  <feGaussianBlur stdDeviation="2" />
                  <feComposite in2="hardAlpha" operator="out" />
                  <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                  <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_389_114" />
                  <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_389_114" result="shape" />
                </filter>
              </defs>
            </svg>
            We provide you, Printing Services.
          </div>
          <p className="sm:text-[15px] md:text-[15px] lg:text-[15px] xl:text-[20px] 2xl:text-[23px] text-gray-700 mt-3 md:mt-0 lg:mb-4 xl:mb-6 leading-relaxed">
            We offer high-quality printing on a variety of fabrics using the latest
            technologies like DTG, screen printing, and heat transfer. Whether you're
            looking to print custom designs on apparel or home décor, we guarantee
            precise results, vibrant colors, and long-lasting prints. Choose from a
            range of fabrics like cotton and polyester, and enjoy a unique
            customization experience for your products.
          </p>
          <button className="sm:px-4 sm:py-2 md:px-4 md:py-1 lg:px-4 lg:py-1 xl:px-6 xl:py-2 2xl:py-4 my-3 md:my-0 bg-black text-white text-base 2xl:text-[20px] rounded-md hover:bg-gray-800">
            Get in printer
          </button>
        </div>

        {/* Right Image */}
        <div className="hidden lg:block">
          <img
            src={Frame14}
            alt="Fabric design"
            className="w-full h-full shadow-lg"
          />
        </div>
      </div>
    </div>
  );
};

export default DigitalPrintingSection;