import React from 'react';
import AboutUs from '../Components/Opal/About/Section1';
import TrendDesignSection  from '../Components/Opal/About/Section2';
import GroupDigitalPrinting  from '../Components/Opal/About/Section3';
import AboutGroupDigitalPrinting  from '../Components/Opal/About/Section4';
import OurCollection  from '../Components/Opal/About/Section5';
import DownloadApp  from '../Components/Opal/About/Section6';
import Conclusion  from '../Components/Opal/About/Section7';


const AboutPage = () => {
    return (
        <div className='overflow-hidden'>
            <AboutUs/>
            <TrendDesignSection/>
            <GroupDigitalPrinting/>
            <AboutGroupDigitalPrinting/>
            <OurCollection/>
            <DownloadApp/>
            <Conclusion/>
        </div>
    );
};

export default AboutPage;
